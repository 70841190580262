export const Coin = require("./images/coin.webp");
export const NoProductFound = require("./images/no_search_result.webp");
export const BlurPrice = require("./images/blur_price.webp");
export const DeliveryIcon = require("./images/delivery_icon.webp");
export const PaymentFailureIcon = require("./images/payment_failure.webp");
export const RemoveItemPopUpIcon = require("./images/removeItemPopUpIcon.webp");
export const DuplicateOrderItemPopUpIcon = require("./images/duplicate_order_icon.webp");
export const EmptyCartItem = require("./images/emptyCartIcon.webp");
export const ClearanceProduct = require("./images/clearance_product.webp");
export const ErrorCow = require("./images/error_cow.webp");
export const NoInternetImage = require("./images/no_internet.webp");
export const FreeDeliveryGif = require("./images/free_delivery.gif");
export const PayOutstanding = require("./images/pay_outstanding.webp");
export const PayCash = require("./images/pay_cash.webp");
export const CancelOrderPopUpIcon = require("./images/cancel_order_pop_up_icon.webp");
export const NoDeliveryIcon = require("./images/no_delivery.webp");
