import { navigationRef } from "app/partner-ordering-ui/utils/RootNavigation";
import { ROUTES } from "app/routes/types";
import store from "app/store";
import { Platform, Dimensions, Linking } from "react-native";
import { clearStorage, removeCookies } from "./storage";
import keycloakLogout from "./apis/keycloakLogout";
import Config from "./config";
import { ToastAndroid } from "app/mocks/ToastAndroid";

export const isMobile = () => {
  return Platform.OS === "android" || Platform.OS === "ios";
};

export const backHandler = () => {
  try {
    if (navigationRef.canGoBack()) {
      navigationRef.goBack();
    } else {
      window.history.back();
    }
    return true;
  } catch (e) {
    return false;
  }
};

export const logout = () => {
  const refreshToken = store.getState().authToken.refreshToken;
  if (refreshToken) {
    // deactivate refresh token from keycloak
    keycloakLogout(refreshToken, Config.CLIENT_ID as string);
  }
  clearAuthCookies();
  clearStorage();
  navigationRef.reset({
    index: 0,
    routes: [{ name: ROUTES.LOGIN }],
  });
};

export const clearAuthCookies = () => {
  removeCookies([
    "access_token",
    "refresh_token",
    "expires_in",
    "id_token",
    "refresh_expires_in",
  ]);
};

export const dialNumber = (phoneNumber: string) => {
  let phoneUrl = `tel:${phoneNumber}`;

  Linking.canOpenURL(phoneUrl)
    .then((supported) => {
      if (supported) {
        Linking.openURL(phoneUrl);
      } else {
        console.log("Unable to dial the number.");
      }
    })
    .catch((err) => console.error("An error occurred", err));
};

export const WINDOW_WIDTH = Dimensions.get("window").width;
export const WINDOW_HEIGHT = Dimensions.get("window").height;
export const SCREEN_WIDTH = Dimensions.get("window").width;
export const SCREEN_HEIGHT = Dimensions.get("window").height;

export const showToast = (
  message: string,
  duration: number = ToastAndroid.SHORT
) => ToastAndroid.show(message, duration);
