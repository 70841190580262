import React, { useState } from "react";
import {
  FlatList,
  Pressable,
  ScrollView,
  Text,
  View,
  StyleSheet,
} from "react-native";
import {
  COLORS,
  FONT_FAMILY_SEMIBOLD,
  FONT_FAMILY_REGULAR,
  Button,
  BottomDrawer,
  FilterIcon,
  TYPOGRAPHY,
  CenterModal,
} from "@dehaat/dds";

import formateDateandTime from "app/sell-to-farmer/utils/helpers/formateDateAndTime";
import DropDownIcon from "app/partner-ordering-ui/icons/DropDownPurple";
import CalenderIcon from "app/partner-ordering-ui/icons/Calender";
import { rnNavigation } from "app/utils/appConfig";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import {
  DIMENSION_HEIGHT,
  DIMENSION_WIDTH,
} from "app/partner-ordering-ui/utils/helpers/common";
import ToastAndroid from "app/mocks/ToastAndroid";
import OTPContainer from "app/components/OTPContainer";

import DehaatIcon from "./icons/DehaatIcon";
import {
  ORDER_ACTIONS,
  ORDER_STATUS,
  ORDER_TYPE,
  RENDER_ACTION,
  isDesktop,
  windowWidth,
} from "../constants/common";
import { Order, OrderCancelReasonObj } from "../models/orders";

import getDateXDaysBefore from "../utils/helpers/getDateXDaysbefore";
import useFarmerOrders from "../hooks/useFarmerOrders";
import { ROUTES } from "../routes/types";
import { ROUTES as APP_ROUTES } from "../../routes/types";

import generateAndValidateOtp from "../utils/apis/generateAndValidateOtp";
import patchOrder from "../utils/apis/patchOrder";
import RejectReasonsModal from "./RejectReasonsModal";
import patchOrderRequest from "../utils/apis/patchOrderRequest";
import ActionButtons from "./ActionButtons";
import HeaderMenu from "app/components/HeaderMenu";

const enum DROPDOWN_TYPE {
  DATE = 1,
  STATUS = 2,
  DELIVERY_TYPE = 3,
}

interface FilterOptions {
  text: string;
  data?: string;
}

interface Filters {
  date?: FilterOptions;
  status?: FilterOptions;
  deliveryType?: FilterOptions;
}

const TableRow = ({
  shipping_label_link,
  manifest_link,
  order_placed_by,
  farmer_details,
  created_at,
  id,
  status,
  total_price,
  type,
  is_credit_order,
  payment,
  order_request_number,
  expires_at,
  grand_total,
}: Order) => {
  const isPickupOrder = type === ORDER_TYPE.SELF_PICKUP;
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [currentAction, setCurrentAction] = useState<RENDER_ACTION>();
  const [orderStatus, setOrderStatus] = useState<ORDER_STATUS>(status);

  const getOrderPaymentStatus = () => {
    if (is_credit_order)
      return (
        <Text
          style={{
            ...styles.paymentLabel,
            color: COLORS.purple100,
            backgroundColor: COLORS.purple20,
          }}
        >
          Credit
        </Text>
      );
    if (+payment?.paid_amount == 0)
      return (
        <Text
          style={{
            ...styles.paymentLabel,
            color: COLORS.purple120,
            backgroundColor: COLORS.purple20,
          }}
        >
          COD
        </Text>
      );
    else
      return (
        <Text
          style={{
            ...styles.paymentLabel,
            color: COLORS.success100,
            backgroundColor: COLORS.success10,
          }}
        >
          {+payment?.paid_amount > 0 && +payment?.paid_amount < +grand_total
            ? "Partially Paid"
            : "Prepaid"}
        </Text>
      );
  };

  const handleActionClick = async (action: ORDER_ACTIONS) => {
    if (action === ORDER_ACTIONS.CONFIRM_PICKUP) {
      const res = await generateAndValidateOtp("generate", {
        farmer_id: farmer_details.id,
        templates: {
          sms: "pick_up_at_store_handshake_hi_v1",
        },
      });
      if (res) {
        ToastAndroid.show("OTP generated successfully", ToastAndroid.SHORT);
        setShowOtpModal(true);
      } else {
        ToastAndroid.show(
          "Error in generating OTP, please try again later",
          ToastAndroid.SHORT
        );
      }
    } else if (action === ORDER_ACTIONS.REJECT) {
      setShowReasonModal(true);
    } else if (action === ORDER_ACTIONS.ACCEPT) {
      handleAcceptOrder();
    }
  };

  const handleOtpSubmit = async (otp: string) => {
    try {
      const res = await generateAndValidateOtp("validate", {
        farmer_id: farmer_details.id,
        otp,
      });
      if (res) {
        const isConfirmed = await patchOrder(id, {
          status: ORDER_STATUS.Delivered,
          reason: "delivered through self_pickup",
        });

        if (isConfirmed) {
          setShowOtpModal(false);
          setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
          ToastAndroid.show(
            "OTP validated successfully, Pickup is confirmed",
            ToastAndroid.SHORT
          );
        } else {
          ToastAndroid.show(
            "Unable to confirm pickup, please try again later.",
            ToastAndroid.SHORT
          );
        }
      }
    } catch {
      ToastAndroid.show(
        "Otp validation failed, Please try again.",
        ToastAndroid.SHORT
      );
    }
  };

  const handleReject = async (reason: OrderCancelReasonObj) => {
    const payload = {
      status:
        isPickupOrder || !order_request_number
          ? ORDER_STATUS.VendorCancelled
          : ORDER_STATUS.VendorRejected,
      reason,
      reject_order_request: true, // This can be changed once we start checking for SO creation for Out of Stock products
    };
    const res =
      isPickupOrder || !order_request_number
        ? await patchOrder(id, payload)
        : await patchOrderRequest("reject", order_request_number, payload);

    if (res) {
      setShowReasonModal(false);
      setOrderStatus(ORDER_STATUS.VendorCancelled);
      setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
      ToastAndroid.show("Order is successfully rejected", ToastAndroid.SHORT);
    } else
      ToastAndroid.show("Error in rejecting the order", ToastAndroid.SHORT);
  };

  const handleAcceptOrder = async () => {
    const payload = {
      status: ORDER_STATUS.VendorConfirmed,
      reason: "",
    };
    const res =
      isPickupOrder || !order_request_number
        ? await patchOrder(id, payload)
        : await patchOrderRequest("accept", order_request_number, payload);

    if (res) {
      setOrderStatus(ORDER_STATUS.VendorConfirmed);
      isPickupOrder
        ? setCurrentAction(RENDER_ACTION.CONFIRM_PICKUP)
        : setCurrentAction(RENDER_ACTION.DONWLOAD_OTPIONS);
      ToastAndroid.show("Order is successfully accepted", ToastAndroid.SHORT);
    } else
      ToastAndroid.show("Error in accepting the order", ToastAndroid.SHORT);
  };

  return (
    <View style={styles.row}>
      <View style={styles.cell}>
        <Text style={styles.boldText}>{id}</Text>
        <Text style={{ ...styles.normalText, marginVertical: 4 }}>
          {formateDateandTime(created_at)}
        </Text>
        <View style={{ alignSelf: "flex-start" }}>
          <Text
            style={{
              ...styles.deliveryLabel,
              color: isPickupOrder ? COLORS.blue120 : COLORS.orange120,
              backgroundColor: isPickupOrder ? COLORS.blue10 : COLORS.orange10,
              borderColor: isPickupOrder ? COLORS.blue100 : COLORS.orange100,
            }}
          >
            {isPickupOrder ? "Pick up at store" : "Home Delivery"}
          </Text>
        </View>
        <Pressable
          onPress={() =>
            rnNavigation({
              name: ROUTES.ORDER_DETAILS,
              params: {
                orderId: id,
              },
            })
          }
        >
          <Text
            style={{
              ...styles.boldText,
              color: COLORS.primary100,
              marginTop: 8,
            }}
          >
            View detail
          </Text>
        </Pressable>
      </View>
      <View style={styles.cell}>
        <Text style={styles.boldText}>{order_placed_by?.name}</Text>
        <Text style={{ ...styles.normalText, marginVertical: 4 }}>
          {farmer_details?.phone_number}
        </Text>
        <Text style={styles.normalText}>
          {farmer_details?.full_display_address}
        </Text>
      </View>
      <View style={styles.cell}>
        <Text style={styles.boldText}>₹ {grand_total || total_price}</Text>
        <View style={{ alignSelf: "flex-start" }}>
          {getOrderPaymentStatus()}
        </View>
      </View>
      <View style={styles.cell}>
        <Text style={styles.boldText}>
          {isPickupOrder || status === ORDER_STATUS.Pending
            ? "__ __"
            : shipping_label_link
            ? "Third Party"
            : "Dehaat Owned"}
        </Text>
      </View>
      <View style={styles.cell}>
        <Text style={styles.boldText}>{orderStatus.replace("_", " ")}</Text>
      </View>

      <View style={{ ...styles.cell, minWidth: isDesktop ? 100 : windowWidth }}>
        <ActionButtons
          status={status}
          isPickupOrder={isPickupOrder}
          shippingLink={shipping_label_link}
          manifestLink={manifest_link}
          expiresAt={expires_at}
          onActionClick={handleActionClick}
          action={currentAction}
        />
      </View>

      <CenterModal
        style={styles.otpModal}
        isVisible={showOtpModal}
        onClose={() => setShowOtpModal(false)}
      >
        <OTPContainer
          number={farmer_details.phone_number}
          maxLength={4}
          onSubmit={handleOtpSubmit}
        />
      </CenterModal>
      <CenterModal
        style={styles.otpModal}
        isVisible={showReasonModal}
        onClose={() => setShowReasonModal(false)}
      >
        <RejectReasonsModal handleReject={handleReject} />
      </CenterModal>
    </View>
  );
};

const getDrawerOptions = (type: DROPDOWN_TYPE) => {
  switch (type) {
    case DROPDOWN_TYPE.DATE:
      return [
        { text: "Last 7 days", data: getDateXDaysBefore(7) },
        { text: "Last 15 days", data: getDateXDaysBefore(15) },
        { text: "Last 30 days", data: getDateXDaysBefore(30) },
        { text: "Last 90 days", data: getDateXDaysBefore(90) },
        { text: "All days", data: undefined },
      ];
    case DROPDOWN_TYPE.STATUS:
      return [
        { text: "Acceptance Pending", data: "PENDING" },
        { text: "Delivery Pending", data: "VENDOR_CONFIRMED" },
        { text: "Delivered", data: "DELIVERED" },
        { text: "Rejected", data: "VENDOR_CANCELLED" },
        { text: "All Orders", data: undefined },
      ];

    default:
      return [
        { text: "Doorstep", data: "DOORSTEP" },
        { text: "Self Pickup", data: "SELF_PICKUP" },
        { text: "All Orders", data: undefined },
      ];
  }
};

const FarmerOrders = () => {
  const [dropDownType, setDropDownType] = useState<DROPDOWN_TYPE | null>(null);
  const [filters, setFilters] = useState<Filters>({});
  const { fetchNext, orders, loading, loadingNext } = useFarmerOrders(
    filters.deliveryType?.data,
    filters.status?.data,
    getDateXDaysBefore(0),
    filters.date?.data
  );

  const handleFilterSelection = (option: FilterOptions) => {
    switch (dropDownType) {
      case DROPDOWN_TYPE.DATE:
        setFilters((prev) => ({ ...prev, date: option }));
        break;

      case DROPDOWN_TYPE.STATUS:
        setFilters((prev) => ({ ...prev, status: option }));
        break;
      default:
        setFilters((prev) => ({
          ...prev,
          deliveryType: option,
        }));
        break;
    }

    setDropDownType(null);
  };

  if (loading) return <Spinner />;

  return (
    <>
      {/* Header */}
      {isDesktop ? (
        <View style={styles.header}>
          <Pressable
            onPress={() => rnNavigation({ name: APP_ROUTES.DASHBOARD })}
            style={{ marginTop: 5, flex: 1 }}
          >
            <DehaatIcon />
          </Pressable>

          <View style={styles.filterContainer}>
            <Text style={styles.filterLabel}>Time period:</Text>
            <Button
              onClick={() => {
                setDropDownType(DROPDOWN_TYPE.DATE);
              }}
              buttonStyle={{ backgroundColor: COLORS.white }}
              textStyle={{ color: COLORS.black }}
              iconLeft={
                <CalenderIcon fill={COLORS.primary100} height={24} width={24} />
              }
              text={filters.date?.text || "Select"}
              iconRight={
                <DropDownIcon
                  svgProps={{
                    height: 24,
                    width: 24,
                    rotation: true ? 180 : 180,
                  }}
                  pathProps={{ stroke: COLORS.black, strokeWidth: 0.5 }}
                />
              }
            />
          </View>
          <View style={styles.filterContainer}>
            <Text style={styles.filterLabel}>Status:</Text>
            <Button
              onClick={() => {
                setDropDownType(DROPDOWN_TYPE.STATUS);
              }}
              buttonStyle={{ backgroundColor: COLORS.white }}
              textStyle={{ color: COLORS.black }}
              text={filters.status?.text || "Select"}
              iconRight={
                <DropDownIcon
                  svgProps={{
                    height: 24,
                    width: 24,
                    rotation: true ? 180 : 0,
                  }}
                  pathProps={{ stroke: COLORS.black, strokeWidth: 0.5 }}
                />
              }
            />
          </View>
          <View style={styles.filterContainer}>
            <Text style={styles.filterLabel}>Delivery type:</Text>
            <Button
              onClick={() => {
                setDropDownType(DROPDOWN_TYPE.DELIVERY_TYPE);
              }}
              buttonStyle={{ backgroundColor: COLORS.white }}
              textStyle={{ color: COLORS.black }}
              text={filters.deliveryType?.text || "Select"}
              iconRight={
                <DropDownIcon
                  svgProps={{
                    height: 24,
                    width: 24,
                    rotation: true ? 180 : 180,
                  }}
                  pathProps={{ stroke: COLORS.black, strokeWidth: 0.5 }}
                />
              }
            />
          </View>
          <HeaderMenu iconColor={COLORS.white} />
        </View>
      ) : (
        <View
          style={{
            ...styles.filterContainer,
            backgroundColor: COLORS.white,
            paddingHorizontal: 17,
            paddingVertical: 22,
            marginLeft: 0,
          }}
        >
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              flexDirection: "row",
              gap: 8,
              alignItems: "center",
            }}
          >
            <Text>Filter By:</Text>
            <Pressable
              onPress={() => {
                setDropDownType(DROPDOWN_TYPE.DATE);
              }}
            >
              <View style={styles.filter}>
                <FilterIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                  }}
                />
                <Text style={styles.filterText}>
                  {filters.date?.text || "Date"}
                </Text>
                <DropDownIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                    rotation: 180,
                  }}
                  pathProps={{ stroke: COLORS.black }}
                />
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                setDropDownType(DROPDOWN_TYPE.STATUS);
              }}
            >
              <View style={styles.filter}>
                <FilterIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                  }}
                />
                <Text style={styles.filterText}>
                  {filters.status?.text || "Order status"}
                </Text>
                <DropDownIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                    rotation: 180,
                  }}
                  pathProps={{ stroke: COLORS.black }}
                />
              </View>
            </Pressable>
            <Pressable
              onPress={() => {
                setDropDownType(DROPDOWN_TYPE.DELIVERY_TYPE);
              }}
            >
              <View style={styles.filter}>
                <FilterIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                  }}
                />
                <Text style={styles.filterText}>
                  {filters.deliveryType?.text || "Delivery type"}
                </Text>
                <DropDownIcon
                  svgProps={{
                    height: 12,
                    width: 12,
                    fill: COLORS.primary100,
                    rotation: 180,
                  }}
                  pathProps={{ stroke: COLORS.black }}
                />
              </View>
            </Pressable>
          </ScrollView>
        </View>
      )}

      <View style={styles.container}>
        {/* <Text style={styles.orderCountText}>50 orders from last 30 days</Text> */}

        {/* Table Content */}
        <View style={styles.table}>
          {isDesktop ? (
            <View style={{ ...styles.row, marginBottom: 14 }}>
              <Text style={styles.rowHeading}>Order Details</Text>
              <Text style={styles.rowHeading}>Customer Details</Text>
              <Text style={styles.rowHeading}>Order Value</Text>
              <Text style={styles.rowHeading}>Shipping By</Text>
              <Text style={styles.rowHeading}>Order status</Text>
              <Text style={styles.rowHeading}>Action</Text>
            </View>
          ) : null}
          <View>
            <FlatList
              data={orders}
              renderItem={({ item }) => <TableRow {...item} />}
              onEndReached={fetchNext}
              onEndReachedThreshold={0.5}
              keyExtractor={(item, index) => `${item.id}-${index}`}
              ListEmptyComponent={
                <Text style={{ ...styles.boldText, alignSelf: "center" }}>
                  No Orders Found
                </Text>
              }
              contentContainerStyle={{ flexGrow: 1, height: DIMENSION_HEIGHT }} // Add this to ensure the content takes full spac
              showsVerticalScrollIndicator={false}
            />
          </View>

          {loadingNext ? <Spinner type="in-screen" /> : null}
        </View>
        <BottomDrawer
          onClose={() => setDropDownType(null)}
          visible={Boolean(dropDownType)}
          modalStyle={{ justifyContent: "center" }}
          heading={`Filter by ${
            dropDownType === DROPDOWN_TYPE.DATE
              ? "Time Range"
              : dropDownType === DROPDOWN_TYPE.STATUS
              ? "Status"
              : "Delivery type"
          }`}
        >
          {dropDownType &&
            getDrawerOptions(dropDownType).map((option) => (
              <Pressable
                key={option.text}
                onPress={() => handleFilterSelection(option)}
              >
                <Text style={styles.dropdownOption}>{option.text}</Text>
              </Pressable>
            ))}
        </BottomDrawer>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  header: {
    backgroundColor: COLORS.primary100,
    height: 69,
    paddingVertical: 10,
    alignItems: "center",
    color: COLORS.white,
    flexDirection: "row",
    paddingHorizontal: 72,
  },
  container: {
    paddingHorizontal: isDesktop ? 56 : 0,
    paddingVertical: 24,
    backgroundColor: "#F2F2F2",
  },
  orderCountText: {
    color: COLORS.neutral80,
    fontSize: 14,
  },
  table: {
    marginTop: 12,
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    marginTop: 8,
    paddingHorizontal: 17,
    flexWrap: isDesktop ? "nowrap" : "wrap",
  },
  rowHeading: {
    flex: 1,
    padding: 14,
    fontSize: 14,
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },
  cell: {
    flex: 1,
    padding: 14,
    minWidth: 163,
  },

  normalText: {
    fontSize: 12,
    fontFamily: FONT_FAMILY_REGULAR,
    color: COLORS.neutral80,
  },
  boldText: {
    fontSize: 12,
    fontFamily: FONT_FAMILY_SEMIBOLD,
  },

  deliveryLabel: {
    borderWidth: 1,
    borderRadius: 12,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  paymentLabel: {
    paddingHorizontal: 12,
    paddingVertical: 3,
    borderRadius: 12,
    marginTop: 4,
  },
  filterLabel: {
    color: COLORS.white,
    marginRight: 10,
  },
  filterContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 10,
  },
  dropdownOption: {
    textAlign: "center",
    padding: 10,
  },
  filter: {
    paddingHorizontal: 12,
    paddingVertical: 4,
    flexDirection: "row",
    backgroundColor: COLORS.primary20,
    alignItems: "center",
    borderRadius: 8,
    gap: 4,
  },
  filterText: {
    color: COLORS.primary100,
    ...TYPOGRAPHY.CAPTION1,
  },
  otpModal: { width: isDesktop ? 420 : DIMENSION_WIDTH },
});

export default FarmerOrders;
