import React, { useCallback, useEffect, useState } from "react";

import SectionLayout from "app/farmer360/components/reusable/SectionLayout";
import FarmReportCard from "./FarmReportCard";
import fetchFarmerFarmList from "app/farmer360/apis/fetchFarmerFarmList";
import { Farm } from "app/farmer360/models/farmer";
import EmptySectionCard from "../reusable/EmtySectionCard";

interface Props {
  farmerId: string;
}

const FarmReportsSection = ({ farmerId }: Props) => {
  const [farms, setFarms] = useState<Farm[]>([]);

  const fetchAndUpdateFarmList = useCallback(async () => {
    const result = await fetchFarmerFarmList(farmerId);
    if (result) setFarms(result);
  }, [farmerId]);

  useEffect(() => {
    fetchAndUpdateFarmList();
  }, [fetchAndUpdateFarmList]);

  return (
    <SectionLayout
      title="Farms"
      description="Farmer’s farms which are tagged with farm location"
    >
      {farms.map((farm) => (
        <FarmReportCard
          key={farm.id}
          farmImage={farm.photo}
          farmName={farm.name}
          land={
            farm.area_by_shape_file
              ? `${farm.area_by_shape_file} ${farm.area_by_shape_file_land_unit}`
              : ""
          }
          latLng={farm.lat_lng}
          reportUrl={farm.farm_nerax_report}
        />
      ))}
      {farms.length === 0 && <EmptySectionCard title={"No tagged farm"} />}
    </SectionLayout>
  );
};

export default FarmReportsSection;
