import React from "react";
import { View, Text, StyleSheet } from "react-native";

import {
  BORDER_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface Props {
  title: string;
}

const EmptySectionCard = ({ title }: Props) => {
  return (
    <View
      style={[
        COMMON_STYLES.flex1,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.justifyCenter,
        COMMON_STYLES.b1,
        BORDER_STYLES.borderNeutral40,
        COMMON_STYLES.br1,
        styles.dashed,
        styles.minHeight,
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.TEXT1,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral80,
        ]}
      >
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  dashed: {
    borderStyle: "dashed",
  },
  minHeight: {
    minHeight: 80,
  },
});

export default EmptySectionCard;
