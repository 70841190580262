import { AxiosError } from "axios";
import { Dimensions, Linking, Platform } from "react-native";

import ToastAndroid from "app/mocks/ToastAndroid";
import { PaymentMethods } from "app/partner-ordering-ui/models/Payment";
import { CartData, CartType } from "app/partner-ordering-ui/models/Cart";
import Config from "app/utils/config";

import { DEHAAT_SUPPORT_PHONE } from "../../constants/Common";
import { navigationRef } from "../RootNavigation";
import { finishCurrentActivity } from "app/utils/appConfig";
import RNFS from "react-native-fs";

export const isMobile = () => {
  return Platform.OS === "android" || Platform.OS === "ios";
};

export const isMobileDevice = () => {
  if (Platform.OS === "web") {
    const userAgent = navigator.userAgent;
    return /mobile/i.test(userAgent) || /tablet/i.test(userAgent); // Simple mobile device check
  }
  return true;
};

export function createUUID() {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export const isDebugBuild = () => {
  return Config.BUILD_TYPE === "debug";
};

export const SCREEN_WIDTH = Dimensions.get("screen").width;
export const SCREEN_HEIGHT = Dimensions.get("screen").height;

export const DIMENSION_WIDTH = Dimensions.get("window").width;
export const DIMENSION_HEIGHT = Dimensions.get("window").height;

export const deepClone = <T>(obj: T) => {
  return JSON.parse(JSON.stringify(obj)) as T;
};

export const openWhatsApp = async () => {
  const url = `whatsapp://send?phone=+91${DEHAAT_SUPPORT_PHONE}`;
  const isSupported = await Linking.canOpenURL(url);
  if (isSupported) {
    await Linking.openURL(url);
  } else {
    ToastAndroid.show(
      "WhatsApp is not installed on your device.",
      ToastAndroid.SHORT
    );
  }
};

export const getCartTypeFromCartData = (cartData: CartData) =>
  getCartType(
    Object.values(cartData)
      .map((cartItem) => cartItem.paymentModes)
      .flatMap((pModes) => pModes)
  );

export const getCartType = (paymentMode: Array<string>) => {
  const containsPrepaid = paymentMode.includes(PaymentMethods.PREPAID);
  const containsCredit = paymentMode.includes(PaymentMethods.CREDIT);

  if (containsPrepaid && containsCredit) {
    return CartType.BOTH_CREDIT_AND_PREPAID;
  } else if (containsCredit) {
    return CartType.ONLY_CREDIT;
  } else if (containsPrepaid) {
    return CartType.ONLY_PREPAID;
  } else {
    return "";
  }
};

export const logAPIExceptionAtSentry = (err: unknown) => {
  try {
    const error = err as AxiosError;
    const errorTraceData: any = {};
    if (error.config) {
      const { headers } = error.config;
      errorTraceData["api-request-trace-id"] = headers["api-request-trace-id"];
    }
    if (error.response) {
      const { config, data, headers } = error.response;
      errorTraceData["ib-request-identifier"] =
        headers?.["ib-request-identifier"];
      if (config?.data) {
        errorTraceData.request = config?.data
          ? JSON.stringify(config.data)
          : undefined;
      }
      if (data) {
        errorTraceData.response = JSON.stringify(data);
      }
    }
    // Sentry.captureException(error, {
    //   extra: errorTraceData,
    // }); // log the exception on sentry
  } catch (e) {}
};

export const logExceptionAndGetTraceIds = (err: unknown) => {
  logAPIExceptionAtSentry(err);
  const error = err as AxiosError;
  const ibRequestId = error?.response?.headers?.["ib-request-identifier"];
  const apiRequestTraceId = error?.config?.headers["api-request-trace-id"];
  let message = ibRequestId ? `IB Trace Id: ${ibRequestId} ` : "";
  if (apiRequestTraceId)
    message = message + "API Request Trace Id: " + apiRequestTraceId;
  return message;
};

export const logExceptionAndGetTraceIdsWithStatusCode = (err: unknown) => {
  logAPIExceptionAtSentry(err);
  const error = err as AxiosError;
  const ibRequestId = error?.response?.headers?.["ib-request-identifier"];
  const statusCode = error?.response?.status;
  const apiRequestTraceId = error?.config?.headers["api-request-trace-id"];
  let message = ibRequestId ? `IB Trace Id: ${ibRequestId} ` : "";
  if (apiRequestTraceId)
    message = message + "API Request Trace Id: " + apiRequestTraceId;
  return { message, statusCode };
};

export const defaultBackPressCallback = () => {
  const back = navigationRef.canGoBack();
  if (back) {
    navigationRef.goBack();
  } else {
    goDefaultPlatformBack();
  }
  return true;
};

export const goDefaultPlatformBack = () => {
  if (Platform.OS === "web") {
    if (window !== undefined) {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.history.replaceState({}, "/home");
      }
    }
  } else finishCurrentActivity();
};

export const saveBase64AsPdf = async (
  base64String: string,
  fileName: string
) => {
  const path = `${RNFS.DocumentDirectoryPath}/${fileName}`;
  try {
    await RNFS.writeFile(path, base64String, "base64");
    return path;
  } catch (error) {
    throw new Error("Failed to save PDF");
  }
};
