import React, { Children, cloneElement, ReactNode } from "react";
import { COLORS } from "@dehaat/dds";

interface SkeletonPlaceholderProps {
  children: ReactNode;
  backgroundColor?: string;
}

interface SkeletonItemProps {
  width: number | string;
  height: number | string;
  borderRadius?: number;
  marginTop?: number;
  backgroundColor?: string;
}

const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> & {
  Item: React.FC<SkeletonItemProps>;
} = ({ children, backgroundColor = COLORS.neutral20 }) => {
  const containerStyle = {
    animation: "pulse 1.5s infinite ease-in-out",
  };

  // Clone children and pass down backgroundColor to all SkeletonPlaceholder.Item components
  const enhancedChildren = Children.map(children, (child) =>
    // Only pass the backgroundColor to children of type SkeletonPlaceholder.Item
    cloneElement(child as React.ReactElement, { backgroundColor })
  );

  return <div style={containerStyle}>{enhancedChildren}</div>;
};

SkeletonPlaceholder.Item = ({
  width,
  height,
  borderRadius = 0,
  marginTop = 0,
  backgroundColor = COLORS.neutral20,
}: SkeletonItemProps) => {
  const itemStyle = {
    width,
    height,
    borderRadius,
    backgroundColor,
    marginTop,
    animation: "pulse 1.5s infinite ease-in-out",
  };

  return <div style={itemStyle}></div>;
};

// Keyframes for pulse animation
const keyframes = `
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
`;

// Inject the pulse keyframes into the document
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default SkeletonPlaceholder;
