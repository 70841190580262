import React, { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";

import {
  BG_STYLES,
  BORDER_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { ROUTES } from "app/sell-to-farmer/routes/types";
import { ROUTES as FARMER_360_ROUTES } from "app/farmer360/routes/types";
import { ResponsiveContext } from "app/context/ResponsiveUIContext";
import DashboardTile from "app/components/DashboardTile";
import { CONTACT_BOOK_IMAGE, ONLINE_ORDERS_IMAGE } from "app/assets/images";
import { rnNavigation } from "app/utils/appConfig";

const Dashboard = () => {
  const { mediaStyles } = useContext(ResponsiveContext);
  return (
    <View
      style={mediaStyles({
        desktopStyle: [COMMON_STYLES.alignItemsCenter, COMMON_STYLES.flex1],
      })}
    >
      <View
        style={[
          BG_STYLES.bgOlive20,
          COMMON_STYLES.br1,
          COMMON_STYLES.fullWidth,
          styles.mt5,
          mediaStyles({ desktopStyle: styles.container }),
        ]}
      >
        <View
          style={[
            COMMON_STYLES.br1,
            BORDER_STYLES.borderOlive100,
            COMMON_STYLES["pt3/2"],
            COMMON_STYLES.ph2,
            COMMON_STYLES.pb2,
          ]}
        >
          <Text
            style={[
              TEXT_STYLES.textNeutral100,
              VARIANTS_STYLES.EMPHASIZED,
              TYPOGRAPHY.HEADING1,
            ]}
          >
            Shop
          </Text>
          <View
            style={[
              COMMON_STYLES.row,
              COMMON_STYLES["mt3/2"],
              COMMON_STYLES.flexWrap,
              { gap: 24 },
            ]}
          >
            <DashboardTile
              name={"Farmers List"}
              image={CONTACT_BOOK_IMAGE}
              onClick={() =>
                rnNavigation({ name: FARMER_360_ROUTES.FARMERS_LIST })
              }
              containerStyle={[
                mediaStyles({ mobileStyle: COMMON_STYLES.fullWidth }),
              ]}
            />
            <DashboardTile
              name={"Online Orders"}
              image={ONLINE_ORDERS_IMAGE}
              onClick={() => rnNavigation({ name: ROUTES.FARMER_ORDERS })}
              containerStyle={[
                mediaStyles({
                  mobileStyle: COMMON_STYLES.fullWidth,
                }),
              ]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mt5: {
    marginTop: 80,
  },
  container: {
    maxWidth: 1171,
  },
});

export default Dashboard;
