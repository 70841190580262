import { get } from "app/sell-to-farmer/utils/axios";
import { Farmer } from "../models/farmer";

interface Response {
  count: number;
  next: string | null;
  previous: string | null;
  results: Farmer[];
}

const fetchFarmerList = async (
  search?: string,
  page?: number,
  offset?: number
) => {
  try {
    const url = "/farmer-360/v1/vendor/farmers-list";
    const response = await get<Response>(url, { search, page, offset });
    return response;
  } catch (e) {
    return null;
  }
};

export default fetchFarmerList;
