import React from "react";
import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { StyleSheet } from "react-native";

import MenuIcon from "app/icons/Menu";
import Menu, { MenuItem, handleCloseMenu } from "app/components/Menu";
import { rnNavigation } from "app/utils/appConfig";
// import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import { ROUTES as STF_ROUTES } from "app/sell-to-farmer/routes/types";
// import { ROUTES as FARMER_360_ROUTES } from "app/farmer360/routes/types";
import { logout } from "app/utils/common";

interface Props {
  iconColor?: string;
}

const HeaderMenu = ({ iconColor }: Props) => {
  return (
    <Menu
      triggerElement={
        <MenuIcon
          height={24}
          width={24}
          style={COMMON_STYLES.mh1}
          fill={iconColor}
        />
      }
    >
      {/* <MenuItem
        text={"Buy Products"}
        icon={<></>}
        onClick={() => {
          rnNavigation({ name: ORDERING_ROUTES.PRODUCT_LIST });
          handleCloseMenu();
        }}
        menuTextStyle={styles.menuTextStyle}
      /> */}
      {/* <MenuItem
        text={"Order History"}
        icon={<></>}
        onClick={() => {
          rnNavigation({
            name: ORDERING_ROUTES.HOME_SCREEN,
          });
          handleCloseMenu();
        }}
        menuTextStyle={styles.menuTextStyle}
      /> */}
      <MenuItem
        text={"My Orders"}
        icon={<></>}
        onClick={() => {
          rnNavigation({ name: STF_ROUTES.FARMER_ORDERS });
          handleCloseMenu();
        }}
        menuTextStyle={styles.menuTextStyle}
      />
      {/* <MenuItem
        text={"Farmer 360"}
        icon={<></>}
        onClick={() => {
          rnNavigation({ name: FARMER_360_ROUTES.FARMER_360_HOME });
          handleCloseMenu();
        }}
        menuTextStyle={styles.menuTextStyle}
      /> */}
      <MenuItem
        text={"Logout"}
        icon={<></>}
        onClick={logout}
        menuTextStyle={styles.menuTextStyle}
      />
    </Menu>
  );
};

const styles = StyleSheet.create({
  menuTextStyle: {
    ...TEXT_STYLES.textNeutral80,
    ...VARIANTS_STYLES.SEMI_EMPHASIZED,
    ...TYPOGRAPHY.TEXT2,
  },
});

export default HeaderMenu;
