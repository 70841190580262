import {
  BG_STYLES,
  BORDER_STYLES,
  CaretDownIcon,
  COLORS,
  COMMON_STYLES,
  formatCurrency,
  LazyImage,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import React, { useContext } from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";

import { ROUTES as STFRoutes } from "app/sell-to-farmer/routes/types";
import { rnNavigation } from "app/utils/appConfig";
import { OrderLine } from "app/farmer360/models/orders";
import { ResponsiveContext } from "app/context/ResponsiveUIContext";

interface Props {
  orderId: string;
  date: string;
  price: string;
  name: string;
  orderLines: OrderLine[];
}

const ImageCountView = ({ count }: { count: number }) => {
  return (
    <View
      style={[
        { height: 80, width: 90 },
        COMMON_STYLES.b1,
        BORDER_STYLES.borderNeutral10,
        COMMON_STYLES["br1/2"],
        BG_STYLES.bgNeutral10,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.justifyCenter,
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.HEADING3,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral90,
        ]}
      >
        +{count}
      </Text>
    </View>
  );
};

const OrderListingCard = ({
  orderId,
  date,
  price,
  name,
  orderLines,
}: Props) => {
  const { mediaStyles } = useContext(ResponsiveContext);
  return (
    <View
      style={[
        COMMON_STYLES.b1,
        BORDER_STYLES.borderBlue20,
        BG_STYLES.bgWhite,
        COMMON_STYLES.br1,
        COMMON_STYLES.ofHidden,
        { width: 328 },
        mediaStyles({
          mobileStyle: COMMON_STYLES.fullWidth,
        }),
      ]}
    >
      <View
        style={[
          { borderBottomWidth: 1, borderBottomColor: COLORS.blue20 },
          COMMON_STYLES.ph1,
          COMMON_STYLES["pv1/2"],
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT1,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral90,
          ]}
        >
          Order ID: {orderId}
        </Text>
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral80,
          ]}
          numberOfLines={1}
        >
          {date} | {formatCurrency(price)} | {name}
        </Text>
      </View>
      <View
        style={[
          COMMON_STYLES.ph1,
          COMMON_STYLES["pv5/4"],
          COMMON_STYLES.row,
          COMMON_STYLES.justifyStart,
          { gap: 16 },
        ]}
      >
        {orderLines
          .slice(0, orderLines.length > 3 ? 2 : orderLines.length)
          .map((line) => (
            <View
              style={[
                { height: 80, width: 90 },
                COMMON_STYLES.b1,
                BORDER_STYLES.borderNeutral10,
                COMMON_STYLES["br1/2"],
              ]}
              key={line.id}
            >
              <View
                style={[
                  COMMON_STYLES.flex1,
                  COMMON_STYLES.alignItemsCenter,
                  COMMON_STYLES.justifyCenter,
                ]}
              >
                <LazyImage
                  imageUrl={line.product.image_url}
                  imgStyles={{ height: 44, width: 34 }}
                />
              </View>

              <View
                style={[
                  { height: 18 },
                  BG_STYLES.bgNeutral10,
                  COMMON_STYLES.fullWidth,
                  COMMON_STYLES.alignItemsCenter,
                  COMMON_STYLES.justifyCenter,
                  COMMON_STYLES["ph1/2"],
                ]}
              >
                <Text
                  style={[TYPOGRAPHY.CAPTION2, VARIANTS_STYLES.MEDIUM]}
                  numberOfLines={1}
                >
                  {line.product_name}
                </Text>
              </View>
            </View>
          ))}
        {orderLines.length > 3 && (
          <ImageCountView count={orderLines.length - 2} />
        )}
      </View>
      <Pressable
        onPress={() =>
          rnNavigation({ name: STFRoutes.ORDER_DETAILS, params: { orderId } })
        }
        style={[
          COMMON_STYLES.row,
          COMMON_STYLES.justifyCenter,
          BG_STYLES.bgSuccess10,
          { height: 48 },
          COMMON_STYLES.alignItemsCenter,
        ]}
      >
        <Text
          style={[
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.SEMI_EMPHASIZED,
            TEXT_STYLES.textPrimary100,
          ]}
        >
          Order Details
        </Text>
        <CaretDownIcon
          svgProps={{
            ...styles.caretIcon,
            style: styles.rotateIcon,
          }}
          pathProps={{ stroke: COLORS.primary100 }}
        />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  caretIcon: { height: 24, width: 24 },
  rotateIcon: {
    transform: [{ rotate: "-90deg" }],
  },
});

export default OrderListingCard;
