import React from "react";
import Svg, { Path, SvgProps } from "react-native-svg";

const FileIcon = (props: SvgProps) => {
  return (
    <Svg width="21" height="20" fill="none" {...props}>
      <Path
        fill="#fff"
        fillRule="evenodd"
        d="M8.435 1.077a.833.833 0 0 1 .59-.244h6.666a2.5 2.5 0 0 1 2.5 2.5v13.334a2.5 2.5 0 0 1-2.5 2.5h-10a2.5 2.5 0 0 1-2.5-2.5v-10c0-.221.088-.433.244-.59l5-5ZM15.691 2.5H9.858v4.167c0 .46-.373.833-.833.833H4.858v9.167c0 .46.373.833.833.833h10c.46 0 .834-.373.834-.833V3.333a.833.833 0 0 0-.834-.833ZM6.037 5.833H8.19V3.68L6.037 5.833Zm.488 5c0-.46.373-.833.833-.833h6.667a.833.833 0 1 1 0 1.667H7.358a.833.833 0 0 1-.833-.834Zm0 3.334c0-.46.373-.834.833-.834h6.667a.833.833 0 1 1 0 1.667H7.358a.833.833 0 0 1-.833-.833Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default FileIcon;
