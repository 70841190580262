import { formatCurrency } from "@dehaat/dds";
import { CART_ERROR_KEYS, DeliveryInfoEnum, PAYMENT_BREAKUP_KEYS } from "../../constants/Cart";
import { PRODUCT_TYPE } from "../../constants/Product";
import {
  AmountDetails,
  BundleSKUCartData,
  CartBundleSKUInfo,
  CartClearanceSKUInfo,
  CartData,
  CartItem,
  CartSingleSKUInfo,
  ClearanceCartItem,
  ClearanceSKUCartData,
  DeliveryInfo,
  ErrorCardItem,
  ErrorDescription,
  PaymentBreakup,
  PRODUCT_STATUS,
  SingleSKUCartData,
  ValidateCart,
} from "../../models/Cart";
import { PaymentMethods } from "../../models/Payment";
import {
  Bundle,
  ClearanceProduct,
  ClearanceSaleProductVariant,
  Product,
  ProductVariant,
} from "../../models/Product";
import { CartInfo, CartState } from "../../store/reducer/cart";
import { deepClone } from "./common";
import { getBundleImages } from "./product";

export const createCartQuery = (
  items: Record<string, CartItem>,
  clearanceItems: Record<string, ClearanceCartItem>
) => {
  const queryItems: (
    | SingleSKUCartData
    | BundleSKUCartData
    | ClearanceSKUCartData
  )[] = [];

  //create query for regular products
  Object.values(items).forEach(({ query }) => queryItems.push(query!));

  //create query for clearance items
  Object.values(clearanceItems).forEach(({ query }) => queryItems.push(query!));

  return queryItems;
};

export const createValidateCartQuery = (
  data: CartData,
  isOrderQuery = false
) => {
  const queryItems: Record<string, any>[] = [];
  Object.values(data).forEach((item) => {
    const singleSKUData = item as CartSingleSKUInfo;
    const query: Record<string, any> = {
      product_id: singleSKUData.id,
      total_order_quantity: singleSKUData.quantity,
      product_type: singleSKUData.productType,
      payment_modes: singleSKUData.paymentModes,
      is_bundle: singleSKUData.isBundle,
      slots: singleSKUData.slots
    };
    if (singleSKUData.isBundle) {
      const comboSKUData = item as CartBundleSKUInfo;
      query.unit_price = comboSKUData.unitPrice;
      query.untaxed_unit_price = comboSKUData.untaxedUnitPrice;
      query.discount_unit_price = comboSKUData.discountedPrice;
      query.applied_discounts = comboSKUData.appliedDiscounts;
      if (isOrderQuery) {
        query.effective_unit_price = comboSKUData.discountedPrice;
        query.payment_mode_discounts = comboSKUData.paymentModeDiscounts;
      }
    } else {
      if (singleSKUData.productType === PRODUCT_TYPE.CLEARANCE) {
        const clearanceSKUData = item as CartClearanceSKUInfo;
        query.unit_price = clearanceSKUData.unitPrice;
        query.discount_unit_price = clearanceSKUData.discountedPrice;
        query.product_expiry = clearanceSKUData.expiryDate;
        query.batch_id = clearanceSKUData.batchId;
        query.untaxed_unit_price = clearanceSKUData.untaxedUnitPrice;
      } else {
        query.unit_price = singleSKUData.unitPrice;
        query.untaxed_unit_price = singleSKUData.untaxedUnitPrice;
        query.applied_discounts = singleSKUData.appliedDiscounts;
        if (
          singleSKUData.discountedPrice &&
          singleSKUData.rewardType === "INSTANT"
        ) {
          query.discount_unit_price = singleSKUData.discountedPrice;
        }
        if (isOrderQuery) {
          query.payment_mode_discounts = singleSKUData.paymentModeDiscounts;
          if (singleSKUData.negotiatedPrice) {
            query.cashback_unit_price = singleSKUData.negotiatedPrice;
            query.cashback_id =
              singleSKUData.discretionaryCouponScheme?.cashback_id;
          }
        }
      }
    }
    queryItems.push(query);
  });

  return queryItems;
};

export const createOrderItemsQuery = (data: CartData) =>
  createValidateCartQuery(data, true);

export const createAmountDetails = (
  { cart_details, payment_details, payment_mode_breakup: pmb }: AmountDetails,
  paymentMethod: PaymentMethods
) => {
  const cartBreakup: Record<string, string | number> = {};
  const paymentBreakup: Record<string, any> = {
    paid_using_credit_limit: pmb[paymentMethod]?.paid_using_credit_limit,
    discount_breakup: {},
  };

  cart_details.cart_breakup.forEach((breakup) => {
    cartBreakup[breakup.key] = breakup.value;
  });

  payment_details.payment_breakup.forEach((breakup) => {
    paymentBreakup[breakup.key] = breakup.value;
    if (breakup.breakup) {
      breakup.breakup.forEach((dbreakup) => {
        paymentBreakup.discount_breakup[dbreakup.key] = dbreakup.value;
      });
    }
  });

  const obj: any = {
    cart_details: {
      order_total: cart_details.order_total,
      delivery_fee: cart_details.delivery_fee,
      cart_breakup: cartBreakup,
    },
    payment_details: {
      amount_payable: payment_details.amount_payable,
      ...paymentBreakup,
    },
    payment_mode_breakup: {
      credit: pmb.credit,
      prepaid: pmb.prepaid,
    },
  };

  delete obj.payment_mode_breakup.credit?.minimum_amount_payable;
  delete obj.payment_mode_breakup.credit?.remaining_used_wallet_balance;
  delete obj.payment_mode_breakup.prepaid?.remaining_used_wallet_balance;

  return obj;
};

export const countItems = (items: Record<string, CartItem>) => {
  if (items)
    return Object.values(items).reduce(
      (totalItemCount, item) =>
        item ? totalItemCount + Number(item.qty) : totalItemCount,
      0
    );
  return 0;
};

export const getWalletBalance = (paymentBreakup: PaymentBreakup[] = []) => {
  if (paymentBreakup.length) {
    return (
      paymentBreakup.find(
        (breakup) => breakup.key === PAYMENT_BREAKUP_KEYS.WALLET_ACTIVE
      )?.value || 0
    );
  }
  return 0;
};

const getInitialQty = (variant: ProductVariant) => {
  return variant?.min_moq_value ? Number(variant.min_moq_value) : 1;
};

const createClearanceItemQueryData = (
  id: string | number,
  variant: ClearanceSaleProductVariant,
  batchId: string
) => {
  const batch = variant.clearance_sales.find(
    (batch) => batch.batch_id === batchId
  );
  const { batch_id, expiry, dehaat_price, delivery_fee } = batch!;
  return {
    id,
    total_order_quantity: 1,
    product_type: PRODUCT_TYPE.CLEARANCE,
    payment_modes: variant.payment_modes,
    is_bundle: false,
    unit_price: variant.dehaat_price,
    discount_unit_price: dehaat_price,
    product_expiry: expiry,
    batch_id,
    delivery_fee,
  };
};

export const createBundleItemQueryData = (bundle: Bundle) => {
  const { id, payment_modes, actual_price, discounted_price, delivery_fee } =
    bundle;
  return {
    id,
    total_order_quantity: 1,
    product_type: PRODUCT_TYPE.REGULAR,
    is_bundle: true,
    payment_modes,
    unit_price: actual_price,
    discount_unit_price: discounted_price,
    delivery_fee,
  };
};

const createSingleItemQueryData = (
  id: string | number,
  variant: ProductVariant,
  qty: number
) => {
  return {
    id,
    total_order_quantity: qty,
    product_type: PRODUCT_TYPE.REGULAR,
    payment_modes: variant.payment_modes,
    is_bundle: false,
    unit_price: variant.dehaat_price,
    delivery_fee: variant.delivery_fee,
  };
};

const createBundleCartData = (product: Product): CartBundleSKUInfo => {
  const {
    id,
    name,
    bundled_products,
    orderable_quantity,
    payment_modes,
    actual_price,
    delivery_fee,
    discounted_price,
  } = product.bundles[0];
  return {
    id: id,
    quantity: 1,
    productType: PRODUCT_TYPE.REGULAR,
    paymentModes: payment_modes,
    isBundle: true,
    unitPrice: Number(actual_price),
    deliveryFee: Number(delivery_fee),
    discountedPrice: Number(discounted_price),
    untaxedUnitPrice: 0,
    name,
    images: getBundleImages(bundled_products),
    totalOrderInventory: orderable_quantity,
    moq: null,
    variantInfo: null,
    productId: product.id,
    subCategory: product.sub_category || "",
    eventData: {
      bundleAvailable: product?.bundles_available,
      alternateProductCount: product.similar_product_count,
      brandName: product.brand?.name,
      clearanceTag: product?.is_clearance_sale,
      category: product.category,
    },
  };
};

const createSingleSKUCartData = (
  variant: ProductVariant,
  qty: number,
  product: Product
): CartSingleSKUInfo => {
  return {
    id: variant.id.toString(),
    isBundle: false,
    name: product.name,
    image: product.image,
    quantity: qty,
    variantInfo: variant!,
    similarProductCount: product.similar_product_count,
    technicalContents: product.technical_contents,
    productType: PRODUCT_TYPE.REGULAR,
    paymentModes: variant.payment_modes,
    totalOrderInventory: variant.orderable_quantity,
    moq: variant.min_moq_value ? Number(variant.min_moq_value) : null,
    moqType: null,
    moqLabelValue: null,
    error: {},
    productId: product.id,
    unitPrice: Number(variant.dehaat_price),
    deliveryFee: Number(variant.delivery_fee),
    untaxedUnitPrice: variant.untaxed_dehaat_price,
    constituentUnitPrice: null,
    uom: variant.constituent_uom,
    discountedPrice: null,
    discountedContituentPrice: null,
    rewardType: "INSTANT",
    subCategory: product.sub_category || "",
    discretionaryCouponScheme: variant.discretionary_coupon_scheme,
    eventData: {
      bundleAvailable: product?.bundles_available,
      alternateProductCount: product.similar_product_count,
      attributes: variant.pack_attribute,
      brandName: product.brand?.name,
      clearanceTag: product?.is_clearance_sale,
      isReturnable: variant?.is_returnable,
      category: product.category,
    },
  };
};

const createClearanceSKUCartData = (
  detail: ClearanceProduct,
  variant: ClearanceSaleProductVariant,
  batchId: string
): CartClearanceSKUInfo => {
  const batch = variant.clearance_sales.find(
    (batch) => batch.batch_id === batchId
  );

  const { batch_id, expiry, dehaat_price, inventory } = batch!;
  return {
    id: variant.id.toString(),
    name: detail.name,
    images: [detail.image],
    quantity: 1,
    unitPrice: Number(variant.dehaat_price),
    deliveryFee: Number(variant.delivery_fee),
    variantInfo: variant!,
    productType: PRODUCT_TYPE.CLEARANCE,
    paymentModes: variant.payment_modes,
    totalVolume: null,
    discountedPrice: Number(dehaat_price),
    constituentUnitPrice: null,
    uom: variant.constituent_uom,
    discountedContituentPrice: null,
    expiryDate: expiry,
    totalOrderInventory: inventory[0].quantity,
    isBundle: false,
    batchId: batch_id,
    untaxedUnitPrice: variant.untaxed_dehaat_price,
    moq: null,
    productId: detail.id,
    subCategory: detail.sub_category || "",
    eventData: {
      bundleAvailable: detail?.bundles_available,
      alternateProductCount: detail?.similar_product_count,
      attributes: variant?.pack_attribute,
      brandName: detail?.brand?.name,
      clearanceTag: detail?.is_clearance_sale,
      isReturnable: variant?.is_returnable,
      category: detail?.category,
    },
  };
};

export const addItemToCartHandler = (
  id: string | number,
  product: Product | ClearanceProduct,
  cart: CartState,
  batchId?: string
) => {
  const newCart = deepClone(cart);

  if (batchId) {
    const { variants } = product as ClearanceProduct;
    const variant = variants.find((v) => Number(v.id) === Number(id));
    if (variant) {
      newCart.clearanceItems[batchId] = {
        qty: 1, // No need to calculate moq in case of clearance items
        detail: product as ClearanceProduct,
        query: createClearanceItemQueryData(id, variant, batchId),
      };
      newCart.cartData[batchId] = createClearanceSKUCartData(
        product as ClearanceProduct,
        variant,
        batchId
      );
    }
    // else -> no variant found handling
  } else {
    const isBundle = product.type === "bundle";
    if (isBundle) {
      newCart.items[id] = {
        qty: 1,
        detail: product,
        query: createBundleItemQueryData(product.bundles[0]),
      };
      newCart.cartData[id] = createBundleCartData(product);
    } else {
      const variant = product.variants.find((v) => Number(v.id) === Number(id));
      if (variant) {
        const qty = getInitialQty(variant);
        newCart.items[id] = {
          qty,
          detail: product,
          query: createSingleItemQueryData(id, variant, qty),
        };
        newCart.cartData[id] = createSingleSKUCartData(variant, qty, product);
      }
      //else handle no variant case
    }
  }
  return newCart;
};

export const updateCartItemHandler = (
  id: string | number,
  cart: CartState,
  qty: number,
  batchId?: string
) => {
  const newCart = deepClone(cart);
  if (batchId) {
    newCart.clearanceItems[batchId].qty = qty;
    newCart.clearanceItems[batchId].query.total_order_quantity = qty;
    newCart.cartData[batchId].quantity = qty;
  } else {
    newCart.items[id].qty = qty;
    newCart.items[id].query.total_order_quantity = qty;
    newCart.cartData[id].quantity = qty;
  }
  return newCart;
};

export const deleteCartItemHandler = (
  id: string | number,
  cart: CartState,
  batchId?: string
) => {
  const newCart = deepClone(cart);
  if (batchId) {
    delete newCart.clearanceItems[batchId];
    delete newCart.cartData[batchId];
  } else {
    delete newCart.items[id];
    delete newCart.cartData[id];
  }
  return newCart;
};

export const updateCartInfo = (data: CartInfo, cart: CartState) => {
  const newCart: CartState = deepClone(cart);
  newCart.data = data;
  return newCart;
};

export const isItemInactiveOrOOS = (
  item: CartSingleSKUInfo | CartBundleSKUInfo | CartClearanceSKUInfo
) => {
  if (item.productStatus === PRODUCT_STATUS.INACTIVE) {
    return "product_inactive";
  }
  if (
    (item.moq && item.totalOrderInventory < item.moq) ||
    item.totalOrderInventory === 0
  ) {
    return "out_of_stock";
  }
  return "";
};

export const getApplicableSchemeText = (
  ids: string[],
  skuIds: string[],
  cartItems: Record<string, CartItem>
) => {
  const name: string[] = [];
  const items = Object.values(cartItems).map((item) => ({
    name: item.detail.name,
    id: item.detail.id,
    skuId: item.query.id,
  }));
  ids.forEach((id, index) => {
    const item = items.find((item) => item.id.toString() === id.toString());
    if (item) {
      if (index > 0 && index === ids.length - 1) {
        name[index - 1] = `${name[index - 1]} & ${item.name}`;
      } else {
        name.push(item.name);
      }
    }
  });

  skuIds.forEach((id, index) => {
    const item = items.find((item) => item.skuId.toString() === id.toString());
    if (item) {
      if (index > 0 && index === ids.length - 1) {
        name[index - 1] = `${name[index - 1]} & ${item.name}`;
      } else {
        name.push(item.name);
      }
    }
  });

  return name.join(", ");
};

export const updateCartData = (
  cartData: CartData,
  validateCardData?: ValidateCart | null
) => {
  const updateCartData: CartData = {};
  Object.values(cartData).forEach((item) => {
    let data = deepClone(item);
    if (data.isBundle) {
      const itemData = data as CartBundleSKUInfo;
      if (validateCardData) {
        const dataFromValidateCart = validateCardData.products.find(
          (p) =>
            Number(p.product_id) === Number(itemData.id) &&
            p.product_type === PRODUCT_TYPE.REGULAR
        );
        if (dataFromValidateCart) {
          itemData.deliveryInfo = dataFromValidateCart.delivery_info;
          itemData.discountedPrice = dataFromValidateCart.effective_unit_price;
          itemData.productStatus = dataFromValidateCart.product_status;
          itemData.licenseStatus = dataFromValidateCart.license_status;
          itemData.totalOrderInventory =
            dataFromValidateCart.total_order_inventory;
          itemData.paymentModeDiscounts =
            dataFromValidateCart.payment_mode_discounts;

          if (dataFromValidateCart.applicable_discounts) {
            itemData.applicableDiscounts =
              dataFromValidateCart.applicable_discounts;
          }

          if (dataFromValidateCart.applied_discounts) {
            itemData.appliedDiscounts = dataFromValidateCart.applied_discounts;
          }

          if (dataFromValidateCart.unit_price !== undefined) {
            const currentPrice = itemData.unitPrice;
            const newPrice = dataFromValidateCart.unit_price;
            itemData.unitPrice = newPrice;
            updateError(CART_ERROR_KEYS.PRICE_CHANGED, itemData, {
              previous: formatCurrency(currentPrice),
              current: formatCurrency(newPrice),
            });
          } else {
            resetError(CART_ERROR_KEYS.PRICE_CHANGED, itemData);
          }
          if (!dataFromValidateCart.payment_modes) {
            resetError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
          } else {
            itemData.paymentModes = dataFromValidateCart.payment_modes;
            if (dataFromValidateCart.payment_modes.length === 1) {
              updateError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
            }
          }
        }
        updateCartData[itemData.id] = itemData;
      }
    } else {
      if (data.productType === PRODUCT_TYPE.CLEARANCE) {
        const itemData = data as CartClearanceSKUInfo;
        if (validateCardData) {
          const dataFromValidateCart = validateCardData.products.find(
            (p) => p.batch_id === itemData.batchId
          );

          if (dataFromValidateCart) {
            itemData.totalVolume = dataFromValidateCart.volume_total;
            itemData.totalOrderInventory =
              dataFromValidateCart.total_order_inventory;
            itemData.deliveryInfo = dataFromValidateCart.delivery_info;
            itemData.licenseStatus = dataFromValidateCart.license_status;
            if (!dataFromValidateCart.is_valid_slot) {
              if (item.slots && item.slots.length > 0)
                updateError(CART_ERROR_KEYS.INVALID_DELIVERY_SLOT, itemData);
              itemData.slots = []
            } else {
              const deliveryDetails = getDeliveryDetails(Math.min(data.quantity, itemData.totalOrderInventory), itemData.deliveryInfo || [])
              itemData.slots?.forEach(item => {
                if (item.order_type == DeliveryInfoEnum.ALL) {
                  item.qty = Math.min(data.quantity, itemData.totalOrderInventory)
                } else {
                  item.qty = deliveryDetails[item.order_type || DeliveryInfoEnum.ALL]
                }
              })
            }
            if (dataFromValidateCart.applicable_discounts) {
              itemData.applicableDiscounts =
                dataFromValidateCart.applicable_discounts;
            }

            if (dataFromValidateCart.unit_price !== undefined) {
              const currentPrice = itemData.unitPrice;
              const newPrice = dataFromValidateCart.unit_price;
              itemData.unitPrice = newPrice;
              updateError(CART_ERROR_KEYS.PRICE_CHANGED, itemData, {
                previous: formatCurrency(currentPrice),
                current: formatCurrency(newPrice),
              });
            } else {
              resetError(CART_ERROR_KEYS.PRICE_CHANGED, itemData);
            }
            if (dataFromValidateCart.untaxed_unit_price) {
              itemData.untaxedUnitPrice =
                dataFromValidateCart.untaxed_unit_price;
            }
            if (dataFromValidateCart.discount_constituent_unit_price) {
              itemData.discountedContituentPrice =
                dataFromValidateCart.discount_constituent_unit_price;
            }
            if (dataFromValidateCart.constituent_uom !== undefined) {
              itemData.uom = dataFromValidateCart.constituent_uom;
            }

            if (dataFromValidateCart.constituent_unit_price) {
              itemData.constituentUnitPrice =
                dataFromValidateCart.constituent_unit_price;
            }
            if (dataFromValidateCart.discount_unit_price) {
              itemData.discountedPrice =
                dataFromValidateCart.discount_unit_price;
            }

            if (!dataFromValidateCart.payment_modes) {
              resetError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
            } else {
              itemData.paymentModes = dataFromValidateCart.payment_modes;
              if (dataFromValidateCart.payment_modes.length === 1) {
                updateError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
              }
            }
          }
        } else {
          //see if we need to check new data from details v2
        }
        updateCartData[itemData.batchId] = itemData;
      } else {
        const itemData = data as CartSingleSKUInfo;
        if (validateCardData) {
          const dataFromValidateCart = validateCardData.products.find(
            (p) =>
              Number(p.product_id) === Number(itemData.id) &&
              p.product_type === PRODUCT_TYPE.REGULAR
          );

          if (dataFromValidateCart) {
            itemData.totalVolume = dataFromValidateCart.volume_total;
            itemData.totalOrderInventory =
              dataFromValidateCart.total_order_inventory;
            itemData.deliveryInfo = dataFromValidateCart.delivery_info;
            itemData.moq = dataFromValidateCart.min_moq_value;
            itemData.moqType = dataFromValidateCart.moq_type;
            itemData.moqLabelValue = dataFromValidateCart.moq_label_value;
            itemData.productStatus = dataFromValidateCart.product_status;
            itemData.licenseStatus = dataFromValidateCart.license_status;
            itemData.paymentModeDiscounts =
              dataFromValidateCart.payment_mode_discounts;
            if (!dataFromValidateCart.is_valid_slot) {
              if (item.slots && item.slots.length > 0)
                updateError(CART_ERROR_KEYS.INVALID_DELIVERY_SLOT, itemData);
              itemData.slots = []
            } else {
              const deliveryDetails = getDeliveryDetails(Math.min(data.quantity, itemData.totalOrderInventory), itemData.deliveryInfo || [])
              itemData.slots?.forEach(item => {
                if (item.order_type == DeliveryInfoEnum.ALL) {
                  item.qty = Math.min(data.quantity, itemData.totalOrderInventory)
                } else {
                  item.qty = deliveryDetails[item.order_type || DeliveryInfoEnum.ALL]
                }
              })
            }
            const {
              unit_price,
              untaxed_unit_price,
              effective_unit_price,
              discount_constituent_unit_price,
              constituent_uom,
              constituent_unit_price,
              applied_discounts,
              payment_modes,
              discount_unit_price,
              applicable_discounts,
            } = dataFromValidateCart;
            if (applicable_discounts) {
              itemData.applicableDiscounts = applicable_discounts;
            }

            if (unit_price !== undefined) {
              const currentPrice = itemData.unitPrice;
              const newPrice = unit_price;
              itemData.unitPrice = newPrice;
              updateError(CART_ERROR_KEYS.PRICE_CHANGED, itemData, {
                previous: formatCurrency(currentPrice),
                current: formatCurrency(newPrice),
              });
            } else {
              resetError(CART_ERROR_KEYS.PRICE_CHANGED, itemData);
            }

            if (untaxed_unit_price) {
              itemData.untaxedUnitPrice = untaxed_unit_price;
            }
            // if effective price then reward type is cashback
            if (effective_unit_price) {
              itemData.discountedPrice = effective_unit_price;
              itemData.rewardType = "CASHBACK";
            }
            // if discount_unit_price then reward type is instant
            if (discount_unit_price !== undefined) {
              itemData.discountedPrice = discount_unit_price;
              itemData.rewardType = "INSTANT";
            }
            if (discount_constituent_unit_price !== undefined) {
              itemData.discountedContituentPrice =
                discount_constituent_unit_price;
            }
            if (constituent_uom !== undefined) {
              itemData.uom = constituent_uom;
            }
            if (constituent_unit_price) {
              itemData.constituentUnitPrice = constituent_unit_price;
            }

            if (applied_discounts) {
              itemData.appliedDiscounts = applied_discounts;
              if (applied_discounts.length === 0) {
                updateError(CART_ERROR_KEYS.COUPON_INACTIVE, itemData);
              } else {
                resetError(CART_ERROR_KEYS.COUPON_INACTIVE, itemData);
              }
            } else {
              resetError(CART_ERROR_KEYS.COUPON_INACTIVE, itemData);
            }

            if (!payment_modes) {
              resetError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
            } else {
              itemData.paymentModes = payment_modes;
              if (payment_modes.length === 1) {
                updateError(CART_ERROR_KEYS.PAYMENT_MODE_UPDATED, itemData);
              }
            }
          }
        } else {
          //see if we need to check new data from details v2
        }
        updateCartData[itemData.id] = itemData;
      }
    }
  });

  return updateCartData;
};

const updateError = (
  key: keyof ErrorCardItem,
  data: CartSingleSKUInfo | CartBundleSKUInfo | CartClearanceSKUInfo,
  params?: Record<string, string>
) => {
  const error: ErrorDescription = {
    key: "",
    params: {},
  };
  switch (key) {
    case CART_ERROR_KEYS.COUPON_INACTIVE:
      error.key = "inactive_coupon_error";
      break;
    case CART_ERROR_KEYS.PAYMENT_MODE_UPDATED:
      error.key = "payment_mode_changed_error";
      error.params = { mode: data.paymentModes[0] };
      break;
    case CART_ERROR_KEYS.PRICE_CHANGED:
      error.key = "price_change_error";
      error.params = params;
      break;
    case CART_ERROR_KEYS.INVALID_DELIVERY_SLOT:
      error.key = "delivery_slot_error";
  }
  if (data.error) data.error[key] = error;
  else data.error = { [key]: error };
};

export const resetError = (
  key: keyof ErrorCardItem,
  data: CartSingleSKUInfo | CartBundleSKUInfo | CartClearanceSKUInfo
) => {
  if (data.error) {
    delete data.error[key];
  }
};

/**
 * This method will check whether there are any item which moq conditions is not reaced
 * Defined MOQ < Selected Quantity
 */
export const checkIsMoqErrorExists = (data: CartData) => {
  return Object.values(data).filter(
    (item) => item.moq && item.moq > item.quantity
  ).length;
};

/**
 * This method will check whether there are any item which is OOS orf Inactive
 */
export const checkIsInactiveOrOOSProductExists = (data: CartData) => {
  return Object.values(data).filter(
    (item) =>
      isItemInactiveOrOOS(item) ||
      item.licenseStatus === PRODUCT_STATUS.INACTIVE
  ).length;
};

export const getDeliveryDetails = (selectedQty: number, deliveryInfo: DeliveryInfo[]) => {
  const deliveryDetails: Record<string, number> = {}
  let preOrderQty = 0;
  let confirmQty = 0;
  let virtualQty = 0;
  if (!deliveryInfo || deliveryInfo.length == 0) {
    confirmQty = selectedQty;
  }
  deliveryInfo?.forEach((element) => {
    switch (element.type) {
      case DeliveryInfoEnum.PRE_ORDER:
        preOrderQty = element.quantity;
        break;
      case DeliveryInfoEnum.CONFIRMED:
        confirmQty = element.quantity;
        break;
      case DeliveryInfoEnum.VIRTUAL_ORDER:
        virtualQty = element.quantity;
        break;
    }
  });
  if (selectedQty > confirmQty) {
    deliveryDetails[DeliveryInfoEnum.CONFIRMED] = confirmQty
    let remainingQty = selectedQty - confirmQty
    if (remainingQty > virtualQty) {
      deliveryDetails[DeliveryInfoEnum.VIRTUAL_ORDER] = virtualQty
      remainingQty -= virtualQty
      if (remainingQty > preOrderQty) {
        deliveryDetails[DeliveryInfoEnum.PRE_ORDER] = preOrderQty
      } else {
        deliveryDetails[DeliveryInfoEnum.PRE_ORDER] = remainingQty
      }
    } else {
      deliveryDetails[DeliveryInfoEnum.VIRTUAL_ORDER] = remainingQty
    }
  } else {
    deliveryDetails[DeliveryInfoEnum.CONFIRMED] = selectedQty
  }
  return deliveryDetails
}

// Pixel 2 API 30
