import { COLORS, COMMON_STYLES } from "@dehaat/dds";
import React from "react";
import { View } from "react-native";
import SkeletonPlaceholder from "react-native-skeleton-placeholder";

const Loader = () => {
  return (
    <View style={COMMON_STYLES.flex1}>
      <SkeletonPlaceholder backgroundColor={COLORS.neutral00}>
        <SkeletonPlaceholder.Item
          height={164}
          width={"100%"}
          borderRadius={8}
        />
      </SkeletonPlaceholder>
    </View>
  );
};

export default Loader;
