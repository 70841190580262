import React from "react";
// import { View } from "react-native";
import { NativeStackNavigationOptions } from "@react-navigation/native-stack";

// import { COMMON_STYLES } from "@dehaat/dds";

// import SearchIcon2 from "app/partner-ordering-ui/icons/SearchIcon2";
// import ProductDetail from "app/partner-ordering-ui/screens/productDetail";
// import MyCart from "app/partner-ordering-ui/screens/cart";
// import ProductSearch from "app/partner-ordering-ui/screens/productSearch";
// import ComboDetailPage from "app/partner-ordering-ui/screens/comboDetail";
// import GrowthPlanList from "app/partner-ordering-ui/screens/growthPlanList";
// import ClearanceSaleList from "app/partner-ordering-ui/screens/clearanceList";
// import CheckoutStrip from "app/partner-ordering-ui/components/CheckoutStrip";
// import ProductList from "app/partner-ordering-ui/screens/productList";
// import {
//   ROUTES as ORDERING_ROUTES,
//   SCREEN_NAMES as ORDER_HISTORY_SCREEN_NAMES,
// } from "app/partner-ordering-ui/routes/types";
import { ROUTES as STFRoutes } from "app/sell-to-farmer/routes/types";
import { ROUTES as FARMER_360_ROUTES } from "app/farmer360/routes/types";
// import OrderSuccess from "app/partner-ordering-ui/screens/orderSuccess";
// import OrderFailure from "app/partner-ordering-ui/screens/orderFailure";
// import OrderPending from "app/partner-ordering-ui/screens/orderPending";
// import CuretadList from "app/partner-ordering-ui/screens/curatedList";
// import CumulativeOffers from "app/partner-ordering-ui/screens/cumulativeOffer";
// import LocalSearch from "app/partner-ordering-ui/screens/localSearch";
// import HeaderIcon from "app/partner-ordering-ui/components/cart/HeaderIcon";
// import CustomProductList from "app/partner-ordering-ui/screens/customProductList";
// import { rnNavigation } from "app/utils/appConfig";
import { ROUTES } from "./types";
import FarmerOrderListing from "app/sell-to-farmer/screens/orderListing";
import { isDesktop } from "app/sell-to-farmer/constants/common";
import OrderDetails from "app/sell-to-farmer/screens/orderDetails";
// import SelectDeliverSlotsScreen from "app/partner-ordering-ui/screens/selectDeliverySlot";
// import OrderHistoryHome from "app/partner-ordering-ui/screens/Home";
// import OTPDetail from "app/partner-ordering-ui/screens/OTPDetail";
// import OrderHistoryDetail from "app/partner-ordering-ui/screens/OrderHistoryDetail";
// import ReturnFlow from "app/partner-ordering-ui/screens/ReturnFlow";
// import HeaderMenu from "app/components/HeaderMenu";
// import PendingPaymentOrder from "app/partner-ordering-ui/screens/PendingPaymentOrder";
import Farmer360Home from "app/farmer360/screens/farmer360";
import FarmersList from "app/farmer360/screens/farmerList";

// export const routesOrdering: Record<
//   string,
//   { component: React.FC<any>; options?: NativeStackNavigationOptions }
// > = {
//   [ORDERING_ROUTES.PRODUCT_SEARCH]: {
//     component: ProductSearch,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.PRODUCT_LIST]: {
//     component: ProductList,
//     options: {
//       title: "Buy Products",
//       headerRight: () => (
//         <View style={[COMMON_STYLES.row, COMMON_STYLES.alignItemsCenter]}>
//           <SearchIcon2
//             height={40}
//             width={40}
//             onPress={() =>
//               rnNavigation({
//                 name: ORDERING_ROUTES.PRODUCT_SEARCH,
//                 params: { navigatingFrm: ORDERING_ROUTES.PRODUCT_LIST },
//               })
//             }
//           />
//           <HeaderMenu />
//         </View>
//       ),
//       headerLeft: () => <></>, //Hide Back From List Since It's Opened In Fragment
//     },
//   },
//   [ORDERING_ROUTES.PRODUCT_DETAIL]: {
//     component: ProductDetail,
//     options: {
//       title: "Product Description",
//     },
//   },
//   [ORDERING_ROUTES.COMBO_DETAIL]: {
//     component: ComboDetailPage,
//     options: {
//       title: "Product Description",
//     },
//   },
//   [ORDERING_ROUTES.MY_CART]: {
//     component: MyCart,
//     options: {
//       title: "Cart",
//       headerRight: () => <HeaderIcon />,
//     },
//   },

//   [ORDERING_ROUTES.GROWTH_PLAN_LIST]: {
//     component: GrowthPlanList,
//     options: {
//       title: "Growth Plan Basket",
//       headerRight: () => (
//         <SearchIcon2
//           height={40}
//           width={40}
//           onPress={() =>
//             rnNavigation({
//               name: ORDERING_ROUTES.PRODUCT_SEARCH,
//               params: {
//                 navigatingFrm: ORDERING_ROUTES.GROWTH_PLAN_LIST,
//               },
//             })
//           }
//         />
//       ),
//     },
//   },
//   [ORDERING_ROUTES.CLEARANCE_SALE_LIST]: {
//     component: ClearanceSaleList,
//     options: {
//       title: "Clearance Sale",
//     },
//   },
//   [ORDERING_ROUTES.CHECKOUT_BANNER]: {
//     component: CheckoutStrip,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.ORDER_SUCCESS]: {
//     component: OrderSuccess,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.ORDER_FAILURE]: {
//     component: OrderFailure,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.ORDER_PENDING]: {
//     component: OrderPending,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.CURATED_LIST]: {
//     component: CuretadList,
//     options: {
//       title: "Curated List",
//     },
//   },
//   [ORDERING_ROUTES.CUMULATIVE_OFFERS]: {
//     component: CumulativeOffers,
//     options: {
//       title: "Offer Details",
//     },
//   },
//   [ORDERING_ROUTES.LOCAL_SEARCH]: {
//     component: LocalSearch,
//     options: {
//       headerShown: false,
//     },
//   },
//   [ORDERING_ROUTES.CUSTOM_PRODUCTS_LIST]: {
//     component: CustomProductList,
//   },
//   [ORDERING_ROUTES.SELECT_DELIVERY_SLOT]: {
//     component: SelectDeliverSlotsScreen,
//   },

//   [ORDERING_ROUTES.HOME_SCREEN]: {
//     component: OrderHistoryHome,
//     options: {
//       title: ORDER_HISTORY_SCREEN_NAMES.ORDER_HISTORY,
//     },
//   },
//   [ORDERING_ROUTES.OTP_DETAIL_SCREEN]: {
//     component: OTPDetail,
//     options: {
//       title: ORDER_HISTORY_SCREEN_NAMES.OTP_DETAILS,
//     },
//   },
//   [ORDERING_ROUTES.ORDER_HISTORY_DETAILS]: {
//     component: OrderHistoryDetail,
//     options: {
//       title: ORDER_HISTORY_SCREEN_NAMES.ORDER_HISTORY_DETAILS,
//     },
//   },
//   [ORDERING_ROUTES.RETURN_FLOW_SCREEN]: {
//     component: ReturnFlow,
//     options: {
//       title: ORDER_HISTORY_SCREEN_NAMES.ORDER_HISTORY_DETAILS,
//     },
//   },
//   [ORDERING_ROUTES.PENDING_PAYMENT]: {
//     component: PendingPaymentOrder,
//     options: {
//       title: ORDER_HISTORY_SCREEN_NAMES.PENDING_PAYMENT,
//     },
//   },
// };

export const routesSTF: Record<
  string,
  { component: React.FC<any>; options?: NativeStackNavigationOptions }
> = {
  [STFRoutes.FARMER_ORDERS]: {
    component: FarmerOrderListing,
    options: {
      headerShown: !isDesktop,
      title: "Online Order",
      headerBackVisible: false,
    },
  },
  [STFRoutes.ORDER_DETAILS]: {
    component: OrderDetails,
    options: {
      headerShown: true,
      title: "Order Details",
    },
  },
};

export const routesFarmer360: Record<
  string,
  { component: React.FC<any>; options?: NativeStackNavigationOptions }
> = {
  [FARMER_360_ROUTES.FARMER_360_HOME]: {
    component: Farmer360Home,
    options: {
      title: "Farmer Profile",
    },
  },
  [FARMER_360_ROUTES.FARMERS_LIST]: {
    component: FarmersList,
    options: {
      title: "Farmers List",
    },
  },
};

export const linking = (() => {
  const screens: Record<string, string> = {};
  screens[ROUTES.LOGIN] = ROUTES.LOGIN;
  screens[ROUTES.DASHBOARD] = ROUTES.DASHBOARD;
  Object.keys({
    // ...routesOrdering,
    ...routesSTF,
    ...routesFarmer360,
  }).forEach((route) => {
    screens[route] = route;
  });

  return {
    config: { screens },
    prefixes: [],
  };
})();
