import React from "react";
import "./loading.css";

interface Props {
  size: number;
  color: string;
}

const LoadingDots = ({ size, color }: Props) => {
  const style = {
    width: size,
    height: size,
    background: color,
  };
  return (
    <div id="wave">
      <span className="dot" style={style}></span>
      <span className="dot" style={style}></span>
      <span className="dot" style={style}></span>
    </div>
  );
};

export default LoadingDots;
