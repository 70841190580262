import { useCallback, useState } from "react";
import { Farmer } from "../models/farmer";
import fetchFarmerList from "../apis/fetchFarmerList";

const LIMIT_OFFSET = 10;

const useDCFarmersList = (search: string) => {
  const [farmersList, setFarmersList] = useState<Farmer[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState<number>(1);
  const [hasNext, setHasNext] = useState(true);

  const fetchNext = useCallback(async () => {
    if (hasNext) {
      setLoadingNext(true);
      const response = await fetchFarmerList(search, page, LIMIT_OFFSET);
      if (response) {
        setFarmersList((prev) => [...prev, ...response.results]);
        setPage((prev) => prev + 1);
        if (response.next === null) {
          setHasNext(false);
        }
        setCount(response.count);
      }
      setLoadingNext(false);
    }
  }, [page, hasNext, search]);

  const getDCFarmersList = useCallback(async () => {
    setLoading(true);
    const response = await fetchFarmerList(search, 1, LIMIT_OFFSET);

    if (response) {
      setFarmersList(response.results);
      setCount(response.count);
      setHasNext(response.next !== null);
      setPage(2);
    } else {
      setFarmersList([]);
      setPage(1);
      setHasNext(false);
    }

    setLoading(false);
  }, [search]);

  return {
    fetchNext,
    loading,
    loadingNext,
    farmersList,
    count,
    getDCFarmersList,
  };
};

export default useDCFarmersList;
