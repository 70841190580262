import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList as OrderingRootStackParamList } from "../partner-ordering-ui/routes/types";
import { RootStackParamList as STFRootStackParamList } from "../sell-to-farmer/routes/types";
import { RootStackParamList as Farmer360StackParamList } from "../farmer360/routes/types";

export enum ROUTES {
  LOGIN = "Login",
  OTP = "Otp",
  DASHBOARD = "Dashboard",
}

export type RootStackParamList = {
  [ROUTES.LOGIN]: undefined;
  [ROUTES.OTP]: {
    isLogin: boolean;
    number: string;
    isWhatsApp: boolean;
  };
  [ROUTES.DASHBOARD]: undefined;
} & OrderingRootStackParamList &
  STFRootStackParamList &
  Farmer360StackParamList;

export type LoginScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.LOGIN
>;

export type OtpScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.OTP
>;

export type DashboardScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.DASHBOARD
>;
