export enum PAYMENT_BREAKUP_KEYS {
  "OVERDUE" = "overdue",
  "DISCOUNT" = "total_applied_discount",
  "WALLET_ACTIVE" = "wallet_balance_used",
  "CASHBACK" = "cashback_as_credit_note",
}

export enum CART_ERROR_CODES {
  "MOQ_CART" = 0,
  "MOQ_PRODUCT_INDIVIDUAL",
  "INVALID_CART",
  "VALIDATE_CART_FAILED",
  "DUPLICATE_ORDER"
}

export enum MOQ_TYPE {
  "VALUE" = "value",
  "QUANTITY" = "quantity",
  "WEIGHT" = "weight",
}

export enum PAYMENT_LABEL {
  "CASH" = "CASH",
  "CREDIT" = "CREDIT",
}

export enum CART_ERROR_KEYS {
  COUPON_INACTIVE = "coupon_inactive",
  PRICE_CHANGED = "price_changed",
  PAYMENT_MODE_UPDATED = "payment_mode_changed",
  INVALID_DELIVERY_SLOT = "delivery_slot_changed"
}

export enum BroadCastEvents {
  ORDER_REQUEST_NUMBER = "order_request_number"
}

export const DeliveryInfoEnum = {
  PRE_ORDER: "preorder",
  CONFIRMED: "confirmed",
  VIRTUAL_ORDER: "virtual_order",
  ALL: "all"
}
