import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { TransactionSummaryV2 } from "../apis/fetchTransactionsSummary";
import { DownloadFormat } from "../common/Constant";

const LEDGER = "ledger";

export interface PartnerData {
  partnerId: string;
  isFinanceDc: boolean;
}
export interface DateFilter {
  fromDate: number | null;
  toDate: number | null;
  type: string | null;
}

export interface DownloadStatment {
  s3_url: string;
  visible: boolean;
  downloadFormat: string;
}
interface LedgerState {
  walletAmount: string;
  appliedFilter: string | null;
  dateFilter: DateFilter | null;
  totalOutstandingAmount: string;
  partnerData: PartnerData;
  transactionSummary: TransactionSummaryV2 | null;
  downloadStatment: DownloadStatment;
  totalPendingAmount: number;
  refreshMandate: boolean;
}

const initialState: LedgerState = {
  walletAmount: "",
  appliedFilter: null,
  dateFilter: null,
  totalOutstandingAmount: "",
  partnerData: {
    partnerId: "",
    isFinanceDc: false,
  },
  transactionSummary: null,
  downloadStatment: {
    visible: false,
    s3_url: "",
    downloadFormat: DownloadFormat.PDF,
  },
  totalPendingAmount: 0,
  refreshMandate: true,
};

export const ledgerSlice = createSlice({
  name: LEDGER, // This define the name of reducer
  initialState,
  reducers: {
    setPartnerData: (state, action: PayloadAction<PartnerData>) => {
      state.partnerData = action.payload;
    },

    setWalletAmount: (state, action: PayloadAction<string>) => {
      state.walletAmount = action.payload;
    },
    setAppliedFilter: (state, action: PayloadAction<string>) => {
      state.appliedFilter = action.payload;
    },
    setDateFilter: (state, action: PayloadAction<DateFilter>) => {
      state.dateFilter = action.payload;
    },
    setTotalOutstandingAmount: (state, action: PayloadAction<string>) => {
      state.totalOutstandingAmount = action.payload;
    },
    setDownloadStatment: (state, action: PayloadAction<DownloadStatment>) => {
      state.downloadStatment = action.payload;
    },
    setTotalPendingAmount: (state, action: PayloadAction<number>) => {
      state.totalPendingAmount = action.payload;
    },
    setRefreshMandate: (state, action: PayloadAction<boolean>) => {
      state.refreshMandate = action.payload;
    },
  },
});

export const {
  setPartnerData,
  setWalletAmount,
  setAppliedFilter,
  setDateFilter,
  setTotalOutstandingAmount,
  setDownloadStatment,
  setTotalPendingAmount,
  setRefreshMandate,
} = ledgerSlice.actions;

export const selectPartnerData = (state: RootState) => state.ledger.partnerData;
export const selectWalletAmount = (state: RootState) =>
  state.ledger.walletAmount;
export const selectAppliedFilter = (state: RootState) =>
  state.ledger.appliedFilter;
export const selectDateFilter = (state: RootState) => state.ledger.dateFilter;
export const selectTotalOutstandingAmount = (state: RootState) =>
  state.ledger.totalOutstandingAmount;
export const selectDownloadStatement = (state: RootState) =>
  state.ledger.downloadStatment;
export const selectTotalPendingAmount = (state: RootState) =>
  state.ledger.totalPendingAmount;
export const selectRefreshMandate = (state: RootState) =>
  state.ledger.refreshMandate;
export default ledgerSlice.reducer;
