import type { NativeStackScreenProps } from "@react-navigation/native-stack";

export enum ROUTES {
  FARMER_360_HOME = "Farmer360Home",
  FARMERS_LIST = "FarmersList",
}

export type RootStackParamList = {
  [ROUTES.FARMER_360_HOME]: {
    farmerId: string;
    name: string;
    phoneNumber: string;
  };
  [ROUTES.FARMERS_LIST]: undefined;
};

export type Farmer360HomeScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.FARMER_360_HOME
>;

export type FarmersListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.FARMERS_LIST
>;
