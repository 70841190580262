import React, { useContext } from "react";
import { View, Text, StyleSheet } from "react-native";

import { ResponsiveContext } from "app/context/ResponsiveUIContext";
import {
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import LoadingDots from "app/farmer360/components/reusable/LoadingDots";

const Row = ({
  label,

  defultValue,
  value,
  loading,
}: {
  label: string;
  defultValue: string;
  value?: string;
  loading: boolean;
}) => {
  return (
    <View style={[COMMON_STYLES.row, COMMON_STYLES["mb1/2"]]}>
      <Text
        style={[
          TEXT_STYLES.textNeutral70,
          TYPOGRAPHY.TEXT2,
          VARIANTS_STYLES.MEDIUM,
          styles.w30,
          COMMON_STYLES["mr0.5"],
        ]}
      >
        {label}:
      </Text>
      {loading ? (
        <LoadingDots size={3} color={COLORS.neutral80} />
      ) : (
        <Text
          style={[
            COMMON_STYLES.flex1,
            TEXT_STYLES.textNeutral80,
            TYPOGRAPHY.TEXT2,
            VARIANTS_STYLES.MEDIUM,
          ]}
        >
          {value || defultValue}
        </Text>
      )}
    </View>
  );
};

const FARMER_INFO = [
  [
    { key: "name", label: "Name" },
    { key: "gender", label: "Gender" },
    { key: "phone", label: "Phone no." },
    { key: "age", label: "Age" },
    { key: "address", label: "Address" },
  ],
  [
    { key: "land", label: "Total Land" },
    { key: "livestock", label: "Livestock" },
  ],
  [
    { key: "transactions", label: "Last 1 Year Transactions" },
    { key: "smartPhpneUser", label: "Smartphone" },
  ],
];

interface Props {
  loading: boolean;
  name?: string;
  gender?: string;
  phone?: string;
  age?: string;
  address?: string;
  land?: string;
  livestock?: string;
  transactions?: string;
  smartPhpneUser?: string;
}

const FarmerProfileInfoCard = ({ ...props }: Props) => {
  const { mediaStyles } = useContext(ResponsiveContext);
  return (
    <View
      style={mediaStyles({
        desktopStyle: [COMMON_STYLES.row, styles["gap7/4"]],
      })}
    >
      {FARMER_INFO.map((info, index) => (
        <View
          key={index}
          style={[
            mediaStyles({
              desktopStyle: styles["flex1/3"],
              mobileStyle: COMMON_STYLES["mb3/2"],
            }),
          ]}
        >
          {info.map(({ key, label }) => (
            <Row
              key={key}
              label={label}
              value={props[key as keyof Props] as string}
              defultValue="--"
              loading={props.loading}
            />
          ))}
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  "flex1/3": { flex: 1 / 3 },
  "gap7/4": { gap: 28 },
  "br5/4": { borderRadius: 24 },
  w30: { width: "30%" },
});

export default FarmerProfileInfoCard;
