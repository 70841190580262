import {
  COLORS,
  COMMON_STYLES,
  CaretDownIcon,
  LazyImage,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import React, { ReactNode } from "react";
import {
  View,
  StyleSheet,
  Text,
  Image,
  StyleProp,
  ViewStyle,
  ImageSourcePropType,
  Pressable,
  TextStyle,
} from "react-native";

export interface DescribedText {
  text: string;
  colour?: string;
  style?: StyleProp<TextStyle>;
}

interface Props {
  heading: DescribedText;
  description?: DescribedText;
  image?: string | null;
  imageFallback?: ReactNode;
  showAll?: boolean;
  isInternalImage?: ImageSourcePropType;
  viewStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<ViewStyle>;
  buttonText?: string | null;
  onClick?: () => void;
  label?: ReactNode;
}

const ActionCard = ({
  heading,
  description,
  image,
  imageFallback,
  showAll,
  isInternalImage,
  viewStyle,
  textStyle,
  buttonText,
  onClick,
  label,
}: Props) => {
  return (
    <Pressable
      style={({ pressed }) => [
        styles.container,
        pressed && COMMON_STYLES["opacity1/2"],
        viewStyle,
      ]}
      onPress={onClick}
    >
      {isInternalImage ? (
        <Image source={isInternalImage} style={styles.img} />
      ) : image || imageFallback ? (
        <LazyImage
          imgStyles={styles.img}
          imageUrl={image}
          fallBackIcon={imageFallback}
        />
      ) : null}
      <View style={[COMMON_STYLES["ph3/4"], COMMON_STYLES.flex1, textStyle]}>
        {heading.text ? (
          <View
            style={[
              COMMON_STYLES.row,
              { columnGap: 8 },
              COMMON_STYLES.alignItemsCenter,
            ]}
          >
            <Text
              style={[
                TYPOGRAPHY.TEXT1,
                VARIANTS_STYLES.MEDIUM,
                TEXT_STYLES.textPrimary120,
                !!heading.colour && {
                  color: heading.colour,
                },
                heading.style,
              ]}
            >
              {heading.text}
            </Text>
          </View>
        ) : null}
        {description?.text ? (
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textPrimary120,
              !!description.colour && {
                color: description.colour,
              },
              description.style,
            ]}
          >
            {description.text}
          </Text>
        ) : null}
        {label}
      </View>
      {buttonText ? (
        <Text style={[TYPOGRAPHY.CAPTION2, styles.btnText]}>{buttonText}</Text>
      ) : (
        showAll && (
          <CaretDownIcon
            svgProps={{
              ...styles.iconSvgStyles,
              style: {
                transform: [{ rotate: "-90deg" }],
              },
            }}
            pathProps={{ stroke: COLORS.primary100 }}
          />
        )
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 1.2,
    borderColor: COLORS.neutral40,
    padding: 12,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  img: {
    height: 48,
    width: 48,
    borderRadius: 24,
  },
  btnText: {
    color: "#0F8040",
  },
  iconSvgStyles: {
    height: 24,
    width: 24,
  },
});

export default ActionCard;
