import React from "react";
import { Text } from "react-native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SUBHEADING_STYLES, TEXT_STYLES } from "@dehaat/dds";

import CustomBackButton from "app/partner-ordering-ui/components/resuable/CustomBackButton";
import { ROUTES as ORDERING_ROUTES } from "app/partner-ordering-ui/routes/types";
import NoInternet from "app/partner-ordering-ui/screens/noInternet";
import { ROUTES as STFRoutes } from "app/sell-to-farmer/routes/types";
import { ROUTES as FARMER_360_ROUTES } from "app/farmer360/routes/types";
import ProtectedRoute from "app/components/ProtectedRoute";
// import AppProvider from "app/components/AppProvider";
import Login from "app/screens/login";
import Otp from "app/screens/otp";
import { backHandler } from "app/utils/common";

import { RootStackParamList, ROUTES } from "./types";
import {
  // routesOrdering,
  routesSTF,
  routesFarmer360,
} from "./Routes";
import { useTranslation } from "react-i18next";

import HeaderMenu from "app/components/HeaderMenu";
import Dashboard from "app/screens/dashboard";

const RootStack = createNativeStackNavigator<RootStackParamList>();

export const RootStackNavigator = () => {
  const { t } = useTranslation();
  return (
    <RootStack.Navigator
      initialRouteName={ROUTES.LOGIN}
      screenOptions={() => {
        return {
          headerLeft: () => <CustomBackButton onBackPress={backHandler} />,
          headerBackVisible: false,
          headerRight: () => <HeaderMenu />,
          headerTitle: ({ children }) => (
            <Text
              style={[SUBHEADING_STYLES.MEDIUM_S3, TEXT_STYLES.textNeutral90]}
            >
              {children}
            </Text>
          ),
          animation: "none",
        };
      }}
    >
      <RootStack.Screen
        name={ROUTES.LOGIN}
        component={Login}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name={ROUTES.OTP}
        component={Otp}
        options={{ headerShown: false }}
      />
      <RootStack.Screen
        name={ORDERING_ROUTES.NO_INTERNET}
        component={NoInternet}
        options={{ headerShown: false }}
      />

      <RootStack.Screen
        name={ROUTES.DASHBOARD}
        component={() => (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        )}
        options={{ title: "Dashboard" }}
      />

      {/** Screen related to sell to farmer */}
      {Object.keys(routesSTF).map((route) => (
        <RootStack.Screen
          key={route}
          name={route as STFRoutes}
          options={routesSTF[route].options}
        >
          {(props) => {
            const Component = routesSTF[route].component;
            return (
              <ProtectedRoute {...props}>
                <Component {...props} />
              </ProtectedRoute>
            );
          }}
        </RootStack.Screen>
      ))}

      {/** Screen related to ordering */}
      {/* {Object.keys(routesOrdering).map((route) => (
        <RootStack.Screen
          key={route}
          name={route as ORDERING_ROUTES}
          options={{
            ...routesOrdering[route].options,
            title: t(routesOrdering[route].options?.title!),
          }}
        >
          {(props) => {
            const Component = routesOrdering[route].component;
            return (
              <ProtectedRoute {...props}>
                <AppProvider>
                  <Component {...props} />
                </AppProvider>
              </ProtectedRoute>
            );
          }}
        </RootStack.Screen>
      ))} */}

      {/** Screen related to farmer 360 */}
      {Object.keys(routesFarmer360).map((route) => (
        <RootStack.Screen
          key={route}
          name={route as FARMER_360_ROUTES}
          options={{
            ...routesFarmer360[route].options,
            title: t(routesFarmer360[route].options?.title!),
          }}
        >
          {(props) => {
            const Component = routesFarmer360[route].component;
            return (
              <ProtectedRoute {...props}>
                <Component {...props} />
              </ProtectedRoute>
            );
          }}
        </RootStack.Screen>
      ))}
    </RootStack.Navigator>
  );
};
