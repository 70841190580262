import React from "react";
import {
  Pressable,
  Image,
  Text,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from "react-native";

import {
  COMMON_STYLES,
  BORDER_STYLES,
  BG_STYLES,
  TEXT_STYLES,
  VARIANTS_STYLES,
  TYPOGRAPHY,
} from "@dehaat/dds";

interface Props {
  name: string;
  image: any;
  onClick: () => void;
  containerStyle?: StyleProp<ViewStyle>;
}

const DashboardTile = ({ name, image, onClick, containerStyle }: Props) => {
  return (
    <Pressable
      onPress={onClick}
      style={({ pressed }) => [
        pressed && COMMON_STYLES["opacity1/2"],
        COMMON_STYLES.b1,
        BORDER_STYLES.borderOlive100,
        COMMON_STYLES.pv1,
        COMMON_STYLES["ph3/2"],
        BG_STYLES.bgWhite,
        COMMON_STYLES.row,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.justifyCenter,
        styles.card,
        containerStyle,
      ]}
    >
      <Image source={image} style={styles.image} />
      <Text
        style={[
          TEXT_STYLES.textNeutral80,
          VARIANTS_STYLES.MEDIUM,
          TYPOGRAPHY.HEADING3,
          COMMON_STYLES["ml3/4"],
        ]}
      >
        {name}
      </Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  image: { height: 40, width: 40 },
  card: {
    borderRadius: 12,
    height: 116,
    width: 240,
  },
});

export default DashboardTile;
