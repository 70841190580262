import { Dimensions } from "react-native";
import { useEffect, useState } from "react";
import { isWeb, getScreenSize } from "app/utils/helpers/responsiveUI";

const deviceWindow = Dimensions.get("window");
const initialMode = getScreenSize(deviceWindow);

export function useScreenModes() {
  const [currentViewMode, setCurrentViewMode] = useState(initialMode);

  useEffect(() => {
    let deviceEventListener = null;
    if (isWeb) {
      deviceEventListener = Dimensions.addEventListener(
        "change",
        handleOrientationChange
      );
    }
    return () => {
      if (deviceEventListener && isWeb) {
        deviceEventListener.remove();
      }
    };
  }, []);

  const handleOrientationChange = (data: any) => {
    if (data?.window) {
      const mode = getScreenSize(data.window);
      if (mode) {
        setCurrentViewMode(mode);
      }
    }
  };

  return currentViewMode;
}
