import { StyleSheet } from "react-native";
import { SCREEN_MODES } from "app/constants/mediaQueryConfig";
import { MediaStyles } from "app/context/ResponsiveUIContext";

const responsiveStyle = (
  { desktopStyle = {}, mobileStyle = {}, tabletStyle = {} }: MediaStyles,
  screenMode: SCREEN_MODES
) => {
  let styles;
  switch (screenMode) {
    case SCREEN_MODES.DESKTOP:
      styles = desktopStyle;
      break;
    case SCREEN_MODES.TABLET:
      styles = tabletStyle;
      break;
    default:
      styles = mobileStyle;
  }
  return StyleSheet.flatten(styles);
};

export default responsiveStyle;
