import { COLORS, LazyImage, PARAGRAPH_STYLES } from "@dehaat/dds";
import React, { PropsWithChildren } from "react";
import {
  StyleSheet,
  View,
  Text,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  TextStyle,
  ImageStyle,
} from "react-native";

interface Props {
  name: string;
  imgUrl: string | null;
  style?: StyleProp<ViewStyle>;
  imageStyle?: StyleProp<ImageStyle>;
  handleItemClick: () => void;
  textStyle?: StyleProp<TextStyle>;
  textNumberOfLines?: number;
}

const RoundImageWithName = ({
  name,
  imgUrl,
  imageStyle,
  style,
  handleItemClick,
  textStyle,
  textNumberOfLines = 1,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={handleItemClick}
    >
      <LazyImage
        imageUrl={imgUrl}
        fallBackIcon={
          <View style={[styles.noImgView, imageStyle]}>
            <Text style={styles.noImgTextOverImg}>{name[0]}</Text>
          </View>
        }
        imgStyles={[styles.image, imageStyle]}
      />
      {children}
      <Text numberOfLines={textNumberOfLines} style={[styles.text, textStyle]}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: 96,
    position: "relative",
    marginRight: 8,
  },
  noImgView: {
    justifyContent: "center",
    alignItems: "center",
    height: 80,
    width: 80,
    borderRadius: 9999,
    backgroundColor: COLORS.neutral20,
  },
  noImgTextOverImg: {
    fontSize: 36,
    lineHeight: 40,
    fontWeight: "600",
    color: COLORS.black,
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  text: {
    color: COLORS.neutral90,
    ...PARAGRAPH_STYLES.RegularT2,
    textAlign: "center",
    marginTop: 4,
  },
});

export default RoundImageWithName;
