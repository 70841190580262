const buildType = process.env.NODE_ENV
  ? process.env.NODE_ENV === "production"
    ? "prod"
    : "debug"
  : "debug";

const Config = {
  APP_CODE: process.env.APP_CODE,
  BUILD_TYPE: buildType,
  MAJOR_VERSION: process.env.MAJOR_VERSION,
  MINOR_VERSION: process.env.MINOR_VERSION,
  PATCH_VERSION: process.env.PATCH_VERSION,
  VERSION_CODE: process.env.VERSION_CODE,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE,
  SENTRY_DIST: process.env.SENTRY_DIST,
  INPUT_BACKEND_URL: process.env.INPUT_BACKEND_URL,
  CLIENT_ID: process.env.KEYCLOAK_CLIENT_ID,
  OIDC_URL: process.env.OIDC_URL,
  KHETI_URL: process.env.KHETI_URL,
  SENTRY_DSN: process.env.SENTRY_DSN,
};

export default Config;
