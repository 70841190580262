import { get } from "app/sell-to-farmer/utils/axios";
import { Order, OrderAggregate } from "../models/orders";
import { API } from "../models/api";

interface Response extends API<Order> {
  orders_aggregate: OrderAggregate;
}

const fetchFarmerOrders = async (farmerId: string) => {
  try {
    const url = `/farmer-360/v1/farmer/${farmerId}/orders`;
    return await get<Response>(url);
  } catch (e) {
    return null;
  }
};

export default fetchFarmerOrders;
