import React, { useContext, useEffect, useState } from "react";
import FarmerProfileInfoCard from "./ProfileInfoCard";
import { View, Text, StyleSheet } from "react-native";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import { ResponsiveContext } from "app/context/ResponsiveUIContext";
import fetchFarmerDetails from "app/farmer360/apis/fetchFarmerDetails";
import { FarmerDetails } from "app/farmer360/models/farmer";

interface Props {
  farmerId: string;
}

const FarmerProfileSection = ({ farmerId }: Props) => {
  const { mediaStyles } = useContext(ResponsiveContext);
  const [loading, setLoading] = useState(true);
  const [farmerData, setFarmerData] = useState<FarmerDetails>();

  const fetchAndSetFarmerDetails = async (id: string) => {
    const details = await fetchFarmerDetails(id);
    if (details) {
      setFarmerData(details);
    }
  };

  useEffect(() => {
    fetchAndSetFarmerDetails(farmerId).finally(() => setLoading(false));
  }, [farmerId]);

  return (
    <View
      style={[
        COMMON_STYLES["ph3/2"],
        COMMON_STYLES.pv1,
        BG_STYLES.bgBlue10,
        COMMON_STYLES.mv1,
        mediaStyles({
          desktopStyle: [styles["br5/4"], styles.minHeight],
        }),
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.HEADING3,
          VARIANTS_STYLES.MEDIUM,
          TEXT_STYLES.textNeutral100,
          COMMON_STYLES.mb2,
        ]}
      >
        Personal Information
      </Text>

      <FarmerProfileInfoCard
        loading={loading}
        name={farmerData?.full_name}
        gender={farmerData?.profile.gender}
        phone={farmerData?.phone_number}
        age={farmerData?.profile.age.toString() || ""}
        address={farmerData?.full_address}
        land={
          farmerData?.profile.total_leased_land
            ? `${farmerData.profile.total_leased_land} ${
                farmerData.profile.total_leased_land_unit || "Acre"
              }`
            : ""
        }
        livestock={farmerData?.livestock_display}
        transactions={farmerData?.last_one_yr_order_total}
        smartPhpneUser={farmerData?.profile.smartphone_user ? "True" : "False"}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  "flex1/3": { flex: 1 / 3 },
  "gap7/4": { gap: 28 },
  "br5/4": { borderRadius: 24 },
  w30: { width: "30%" },
  minHeight: { minHeight: 264 },
});

export default FarmerProfileSection;
