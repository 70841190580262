import { CumulativeOfferSections } from "../models/Product";

export enum StringBoolean {
  FALSE = "FALSE",
  TRUE = "TRUE",
}

export const SUPPORTED_LANG = {
  hi: "hi", // <-- DEFAULT LANG
  en: "en",
  bn: "bn",
  mr: "mr",
  gu: "gu",
};

export const NAMESPACE = {
  common: "common",
};

export const DEHAAT_SUPPORT_PHONE = "8506909095";
export const ORDER_HISTORY_REDIRETION_TIME_IN_SECONDS = 5;

export const MAX_INVENTORY_ALLOWED = 99999;

export const DEFAULT_CUMULATIVE_DATA: CumulativeOfferSections = [
  {
    name: "Order value added",
    value: "0",
  },
  {
    name: "Discount slab",
    value: "Not Applicable",
  },
  {
    name: "Total discount",
    value: "0",
  },
];

export enum FILTER_VALUES {
  "ALL_PRODUCTS" = "",
  "NAME" = "name",
  "IN_STOCK" = "-in_stock",
}

export enum USER_ROLES {
  DEHAAT_COORDINATOR = "dehaat_coordinator",
}

export enum InvoiceListType {
  CREDIT_NOTE = "CREDIT_NOTE",
  INVOICE = "INVOICE",
}
