import React, { useContext, useEffect, useState } from "react";
import { FlatList, StyleSheet, View, Text } from "react-native";
import {
  BG_STYLES,
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import { ResponsiveContext } from "app/context/ResponsiveUIContext";
import FarmerCard from "../components/farmerList/FarmerCard";
import { rnNavigation } from "app/utils/appConfig";
import { ROUTES as FARMER_360_ROUTES } from "../../farmer360/routes/types";
import SearchInput from "../components/reusable/SearchInput";
import EmptyList from "app/components/EmptyList";
import useDCFarmersList from "../hooks/useDCFarmersList";
import useDebounce from "../hooks/useDebounce";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";
import { useIsFocused } from "@react-navigation/native";

const DEFAULT_COLORS = [
  COLORS.mulberry100,
  COLORS.success100,
  COLORS.olive100,
  COLORS.blue100,
  COLORS.error100,
  COLORS.neutral100,
  COLORS.orange100,
  COLORS.purple100,
];

const FarmersList = () => {
  const { mediaStyles } = useContext(ResponsiveContext);
  const isFocused = useIsFocused();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const debouncedSeachQuery = useDebounce(searchQuery);

  const {
    count,
    farmersList,
    getDCFarmersList,
    fetchNext,
    loading,
    loadingNext,
  } = useDCFarmersList(debouncedSeachQuery);

  useEffect(() => {
    if (isFocused) {
      getDCFarmersList().then(() => {
        if (isPageLoading) {
          setIsPageLoading(false);
        }
      });
    }
  }, [isFocused, debouncedSeachQuery, getDCFarmersList, isPageLoading]);

  return (
    <View
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.justifyCenter,
        BG_STYLES.bgWhite,
        COMMON_STYLES.fullHeight,
        COMMON_STYLES.pt1,
        COMMON_STYLES.ph1,
      ]}
    >
      <View
        style={[COMMON_STYLES.flex1, mediaStyles({ desktopStyle: styles.mw })]}
      >
        <SearchInput
          value={searchQuery}
          setValue={setSearchQuery}
          onClear={() => setSearchQuery("")}
          placeholder="Search by name, phone number"
          containerStyle={COMMON_STYLES["p3/4"]}
        />

        {isPageLoading ? (
          <Spinner />
        ) : (
          <FlatList
            data={farmersList}
            ListHeaderComponent={
              <View
                style={[
                  COMMON_STYLES.ph1,
                  COMMON_STYLES.pt1,
                  COMMON_STYLES["pb1/2"],
                  BG_STYLES.bgWhite,
                ]}
              >
                <Text
                  style={[
                    TYPOGRAPHY.HEADING3,
                    VARIANTS_STYLES.MEDIUM,
                    TEXT_STYLES.textNeutral80,
                  ]}
                >
                  Farmer List ({count || 0})
                </Text>
              </View>
            }
            stickyHeaderIndices={[0]}
            renderItem={({ item, index }) => (
              <FarmerCard
                imageURL={null}
                name={item.full_name}
                address={item.short_address}
                onClick={() =>
                  rnNavigation({
                    name: FARMER_360_ROUTES.FARMER_360_HOME,
                    params: {
                      farmerId: item.id,
                      name: item.full_name,
                      phoneNumber: item.phone_number,
                    },
                  })
                }
                phoneNumber={item.phone_number}
                backgroundColor={DEFAULT_COLORS[index % 4]}
              />
            )}
            ListEmptyComponent={EmptyList}
            onEndReached={fetchNext}
            ListFooterComponent={
              <>
                {loading && <Spinner type="on-screen" />}
                {loadingNext && <Spinner />}
              </>
            }
            keyExtractor={({ id }) => id}
            contentContainerStyle={COMMON_STYLES.pb2}
            showsVerticalScrollIndicator={false}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mw: {
    maxWidth: 1260,
  },
});

export default FarmersList;
