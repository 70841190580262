import {
  TYPOGRAPHY,
  VARIANTS_STYLES,
  TEXT_STYLES,
  COMMON_STYLES,
} from "@dehaat/dds";
import { t } from "i18next";
import React from "react";
import { Text } from "react-native";

interface Props {
  title?: string | null;
}
const EmptyList = ({ title = null }: Props) => {
  return (
    <Text
      style={[
        TYPOGRAPHY.HEADING3,
        VARIANTS_STYLES.EMPHASIZED,
        TEXT_STYLES.textNeutral100,
        COMMON_STYLES.textCenter,
        COMMON_STYLES.justifyCenter,
        COMMON_STYLES.alignItemsCenter,
        COMMON_STYLES.flex1,
        COMMON_STYLES.p1,
      ]}
    >
      {`${title ?? t("no_results_found")}`}
    </Text>
  );
};

export default EmptyList;
