import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SCROLL_VIEW_KEYS } from "app/partner-ordering-ui/constants/Product";
import { CuratedListRouteParams } from "../models/CuratedList";
import { ResponseDeliveryOtp } from "../apis/orderHistory/fetchOtplist";

export enum ROUTES {
  PRODUCT_SEARCH = "ProductSearch",
  PRODUCT_LIST = "ProductList",
  PRODUCT_DETAIL = "ProductDetail",
  MY_CART = "MyCart",
  COMBO_DETAIL = "ComboDetail",
  GROWTH_PLAN_LIST = "GrowthPlanList",
  CLEARANCE_SALE_LIST = "ClearanceSaleList",
  CHECKOUT_BANNER = "GoToCartBanner",
  ORDER_SUCCESS = "OrderSuccess",
  ORDER_FAILURE = "OrderFailure",
  ORDER_PENDING = "OrderPending",
  CURATED_LIST = "CuratedList",
  CUMULATIVE_OFFERS = "CumulativeOffers",
  LOCAL_SEARCH = "LocalSearch",
  CUSTOM_PRODUCTS_LIST = "CustomProductList",
  NO_INTERNET = "NoInternet",
  SELECT_DELIVERY_SLOT = "SelectDeliverySlot",
  SELECT_PAYMENT_ACCOUNT = "SelectPaymentAccount",
  HOME_SCREEN = "OrderHistoryHome",
  OTP_DETAIL_SCREEN = "OTPDetailScreen",
  ORDER_HISTORY_DETAILS = "OrderHistoryDetails",
  RETURN_FLOW_SCREEN = "ReturnFlowScreen",
  PENDING_PAYMENT = "PendingPayment",
}


export enum SCREEN_NAMES {
  ORDER_HISTORY = "Order History",
  OTP_DETAILS = "OTP Details",
  ORDER_HISTORY_DETAILS = "Order Details",
  PENDING_PAYMENT = "Pending Payment",
}

export type RootStackParamList = {
  [ROUTES.PRODUCT_SEARCH]: {
    navigatingFrm?: string;
  };
  [ROUTES.PRODUCT_LIST]: undefined;
  [ROUTES.PRODUCT_DETAIL]: {
    id: string;
    variantId?: string;
    scrollKey?: SCROLL_VIEW_KEYS;
    navigationFrom?: string | undefined;
  };
  [ROUTES.COMBO_DETAIL]: { id: string };
  [ROUTES.MY_CART]: { landingFrom?: string };
  [ROUTES.GROWTH_PLAN_LIST]: undefined;
  [ROUTES.CLEARANCE_SALE_LIST]: { id: string };
  [ROUTES.CHECKOUT_BANNER]: undefined;
  [ROUTES.ORDER_SUCCESS]: { id: number; amount: number };
  [ROUTES.ORDER_FAILURE]: undefined;
  [ROUTES.ORDER_PENDING]: { id: number; amount: number };
  [ROUTES.ORDER_PENDING]: { id: number; amount: number };
  [ROUTES.CURATED_LIST]: undefined | CuratedListRouteParams;
  [ROUTES.CUMULATIVE_OFFERS]: {
    query: string;
    identifier: string;
    slabs: string;
  };
  [ROUTES.LOCAL_SEARCH]: {
    searchKey: string;
    itemList: Record<string, string | any>;
    navigatingFrm?: string;
  };
  [ROUTES.CUSTOM_PRODUCTS_LIST]: undefined;
  [ROUTES.NO_INTERNET]: undefined;
  [ROUTES.SELECT_DELIVERY_SLOT]: { ids: string };
  [ROUTES.SELECT_PAYMENT_ACCOUNT]: {
    orderId: number;
    orderAmount?: number;
    orderType?: string;
  };

  // Order History Route
  [ROUTES.HOME_SCREEN]: {
    index: number;
  };

  [ROUTES.OTP_DETAIL_SCREEN]: {
    detail: ResponseDeliveryOtp;
    partnerId: string;
  };

  [ROUTES.ORDER_HISTORY_DETAILS]: {
    partnerId: string;
    orderRequestNumber: string;
  };
  [ROUTES.RETURN_FLOW_SCREEN]: {
    isReturnable: boolean;
    price: string;
    productId: string;
    productName: string;
    productBrand?: string;
    totalQuantity: string;
    packType?: string;
    productImage?: string;
    deliveryId?: string;
    deliveryLineId: number;
    returnPolicy: string|undefined;
  };
  [ROUTES.PENDING_PAYMENT]: undefined
};

export type ProductSearchScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.PRODUCT_SEARCH
>;

export type ProductListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.PRODUCT_LIST
>;

export type ProductDetailScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.PRODUCT_DETAIL
>;

export type ComboDetailScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.COMBO_DETAIL
>;

export type MyCartScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.MY_CART
>;

export type GrowthPlanListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.GROWTH_PLAN_LIST
>;

export type ClearanceSaleListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.CLEARANCE_SALE_LIST
>;

export type CheckoutBannerScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.CHECKOUT_BANNER
>;

export type OrderSuccessScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.ORDER_SUCCESS
>;

export type OrderFailureScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.ORDER_FAILURE
>;

export type OrderPendingScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.ORDER_PENDING
>;

export type CuratedListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.CURATED_LIST
>;

export type CumulativeOffersScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.CUMULATIVE_OFFERS
>;

export type LocalSearchScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.LOCAL_SEARCH
>;

export type SelectDeliverySlotsScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.SELECT_DELIVERY_SLOT
>;

export type CustomProductListScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.CUSTOM_PRODUCTS_LIST
>;

export type SelectPaymentAccountScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.SELECT_PAYMENT_ACCOUNT>;
  
export type HomeScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.HOME_SCREEN
>;

export type OTPDetailsProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.OTP_DETAIL_SCREEN
>;

export type OrderHistoryDetailsProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.ORDER_HISTORY_DETAILS
>;

export type ReturnFlowScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.RETURN_FLOW_SCREEN
>;

export type PendingPaymentScreenProps = NativeStackScreenProps<
  RootStackParamList,
  ROUTES.PENDING_PAYMENT
>;