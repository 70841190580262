import { get } from "app/sell-to-farmer/utils/axios";
import { FarmerDetails } from "../models/farmer";

const fetchFarmerDetails = async (farmerId: string | number) => {
  try {
    const url = `/farmer-360/v1/farmer/${farmerId}/detail`;
    return await get<FarmerDetails>(url);
  } catch (e) {
    return null;
  }
};

export default fetchFarmerDetails;
