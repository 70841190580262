import { Platform } from "react-native";
import {
  SCREEN_MODES,
  SMALL_SCREEN_WIDTH,
  TABLET_SCREEN_WIDTH,
} from "app/constants/mediaQueryConfig";

export const isWeb: boolean = Platform.OS === "web";

// Determine the dimension mode of the screen based on window dimensions
export const getScreenSize = (window: any) => {
  if (!window || !window.width || !window.height) {
    // If window dimensions are not available, assume mobile screen
    return SCREEN_MODES.MOBILE;
  }
  const { width: screenWidth, height: screenHeight } = window;
  if (screenWidth >= TABLET_SCREEN_WIDTH) {
    return SCREEN_MODES.DESKTOP;
  }
  if (screenWidth < SMALL_SCREEN_WIDTH) {
    return SCREEN_MODES.MOBILE;
  }
  return SCREEN_MODES.TABLET;
};
