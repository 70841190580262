export const PAGE_SIZE = 10;
export const INITIAL_PAGE = 1;
export const WALLET = "Wallet";
export const DBA = "DBA";
export const MOBILE = "Mobile";
export const INPUT = "Input";
export const OUTSTANDING = "outstanding";
export const PaymentOption = {
  TOTAL: "total",
  CUSTOM: "custom",
};
export const RAZORPAY = "RAZORPAY";
export const AccountType = {
  GAPL: "gapl",
  LENDER: "lender",
};
export const DownloadFormat = {
  PDF: "pdf",
  EXCEL: "xlsx",
};
export const DownLoadInvoiceType = {
  INVOICE: "INVOICE",
  CREDIT_NOTE: "CREDIT_NOTE",
  DEBIT_NOTE: "DEBIT_NOTE",
};
export const SAP = "SAP";
export const BASE64 = "base64";
export const WidgetType = {
  OVERDUE: "OVERDUE",
  INTEREST: "INTEREST",
};
export const ON_HOLD = "ONHOLD";
export enum DownloadStatus {
  Downloading = "downloading",
  Done = "done",
  Failed = "failed",
}
export enum DownloadAction {
  ViewInvoice = "View Invoice",
  Retry = "Retry",
  Ok = "Ok",
}
export const DELAY_SUCCESS_FAILURE_SCREEN_TIME = 5000;
export const DEFAULT_CURRENCY = "₹";
export const MISCELLANEOUS = "MISCELLANEOUS";
export const AGED_OUTSTANDING = "AGED OUTSTANDING";
export const REPAYMENT_FREQUENCY = "REPAYMENT FREQUENCY";
export const ON_BOARDING_POD = "ONBOARDING POD";

export const AgeingBannerPriority = {
  PRIORITY_0: "P0",
  PRIORITY_1: "P1",
  PRIORITY_2: "P2",
  PRIORITY_3: "P3",
};
