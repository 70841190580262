import React, { PropsWithChildren, useCallback } from "react";
import { useScreenModes } from "app/hooks/useScreenModes";
import { SCREEN_MODES } from "app/constants/mediaQueryConfig";
import ResponsiveStyle from "app/styles/responsive";
import { ImageStyle, StyleProp, TextStyle, ViewStyle } from "react-native";

export type CustomStyle = StyleProp<ViewStyle | TextStyle | ImageStyle>;

export type MediaStyles = Partial<{
  desktopStyle: CustomStyle;
  tabletStyle: CustomStyle;
  mobileStyle: CustomStyle;
}>;

interface ResponsiveContextProps {
  currentViewMode: SCREEN_MODES;
  mediaStyles: (mediaStyles: MediaStyles) => ViewStyle | TextStyle | ImageStyle;
}

const initialState = {
  currentViewMode: SCREEN_MODES.MOBILE,
  mediaStyles: (mediaStyles: MediaStyles) =>
    ResponsiveStyle(mediaStyles, SCREEN_MODES.MOBILE),
};

export const ResponsiveContext =
  React.createContext<ResponsiveContextProps>(initialState);

const MediaProvider = ({ children }: PropsWithChildren) => {
  const currentViewMode = useScreenModes();

  const getResponsiveStyles = useCallback(
    (mediaStyles: MediaStyles) => ResponsiveStyle(mediaStyles, currentViewMode),
    [currentViewMode]
  );

  return (
    <ResponsiveContext.Provider
      value={{ currentViewMode, mediaStyles: getResponsiveStyles }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

export default MediaProvider;
