import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  ReactNode,
  PropsWithChildren,
} from "react";

import {
  View,
  Pressable,
  StyleSheet,
  Modal,
  GestureResponderEvent,
  TouchableOpacity,
  Text,
  ViewStyle,
  StyleProp,
  TextStyle,
} from "react-native";
import { WINDOW_HEIGHT, WINDOW_WIDTH } from "../utils/common";
import {
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

interface MenuProps extends PropsWithChildren {
  triggerElement: ReactNode;
  isBackgroundBlurred?: boolean;
  handleTriggerElementPress?: () => void;
}

interface MenuItemProps {
  onClick: () => void;
  text: string;
  icon: ReactNode;
  menuStyle?: StyleProp<ViewStyle>;
  menuTextStyle?: StyleProp<TextStyle>;
}

let handleCloseMenu = () => {};

const Menu = ({
  triggerElement,
  isBackgroundBlurred = false,
  handleTriggerElementPress,
  children,
}: MenuProps) => {
  const triggerWrapperRef = useRef<View | null>(null);
  const itemsWrapperRef = useRef<View | null>(null);
  const [menuVisible, setMenuVisible] = useState(false);

  const [triggerDimensions, setTriggerDimensions] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [modalDimensions, setModalDimensions] = useState({
    width: 0,
    height: 0,
  });

  handleCloseMenu = () => {
    setMenuVisible(false);
    setModalDimensions({ width: 0, height: 0 });
    setTriggerDimensions({ top: 0, left: 0, width: 0, height: 0 });
  };

  const handleTriggerPress = () => {
    setMenuVisible(true);
    handleTriggerElementPress?.();
  };

  const handleOutsidePress = (event: GestureResponderEvent) => {
    if (event.target === event.currentTarget) {
      handleCloseMenu();
    }
  };

  const { top, left } = useMemo(() => {
    let leftPos = 0;
    let topPos = 0;

    leftPos =
      triggerDimensions.left - modalDimensions.width + triggerDimensions.width;
    // if the popup is outside the screen from the left
    if (triggerDimensions.left - modalDimensions.width < 0) {
      leftPos = triggerDimensions.left;
    }

    const initialTriggerTop = triggerDimensions.top + triggerDimensions.height;

    topPos =
      initialTriggerTop + modalDimensions.height > WINDOW_HEIGHT
        ? initialTriggerTop - triggerDimensions.height - modalDimensions.height
        : initialTriggerTop;

    return { top: topPos, left: leftPos };
  }, [modalDimensions, triggerDimensions]);

  const menuPositionStyles = { left, top };

  const calculateDimensions = () => {
    triggerWrapperRef?.current?.measureInWindow((x, y, width, height) => {
      setTriggerDimensions({
        top: Math.max(y, 0),
        left: x,
        width,
        height,
      });
    });

    setTimeout(() => {
      itemsWrapperRef?.current?.measureInWindow((x, y, width, height) => {
        setModalDimensions({ width, height });
      });
    }, 100);
  };

  useEffect(() => {
    if (menuVisible) {
      if (triggerWrapperRef?.current) {
        calculateDimensions();
      }
    }
  }, [menuVisible, itemsWrapperRef, setModalDimensions]);

  return (
    <>
      <Pressable onPress={handleTriggerPress} ref={triggerWrapperRef}>
        {triggerElement}
      </Pressable>
      <Modal
        transparent={true}
        visible={menuVisible}
        animationType="fade"
        onRequestClose={() => setMenuVisible(false)}
      >
        <Pressable
          style={[
            styles.modalWrapper,
            isBackgroundBlurred ? styles.blurBackground : undefined,
          ]}
          onPress={handleOutsidePress}
        >
          <View
            collapsable={false}
            ref={itemsWrapperRef}
            style={[
              styles.activeSection,
              modalDimensions.width !== 0 && triggerDimensions.left !== 0
                ? styles.opacity1
                : styles.opacity0,
              menuPositionStyles,
            ]}
          >
            {children}
          </View>
        </Pressable>
      </Modal>
    </>
  );
};

export const MenuItem = ({
  onClick,
  text,
  icon,
  menuStyle,
  menuTextStyle,
}: MenuItemProps) => {
  return (
    <TouchableOpacity
      onPress={onClick}
      style={[COMMON_STYLES["p3/4"], menuStyle, COMMON_STYLES.row]}
    >
      {icon}
      <Text
        style={[
          COMMON_STYLES["ml0.5"],
          TEXT_STYLES.textNeutral80,
          TYPOGRAPHY.CAPTION1,
          VARIANTS_STYLES.BASE,
          menuTextStyle,
        ]}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  modalWrapper: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 10,
  },

  blurBackground: {
    backgroundColor: "rgba(1, 1, 1, 0.2)",
  },

  activeSection: {
    backgroundColor: "white",
    alignSelf: "flex-start",
    maxWidth: WINDOW_WIDTH * 0.7,
    elevation: 8,
    zIndex: 99,
    borderRadius: 8,
  },
  opacity0: {
    opacity: 0,
  },
  opacity1: {
    opacity: 1,
  },
});

export { handleCloseMenu };

export default Menu;
