import React, { PropsWithChildren, useContext } from "react";
import { View, Text, StyleSheet, ScrollView } from "react-native";

import { ResponsiveContext } from "app/context/ResponsiveUIContext";
import {
  BG_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import Spinner from "app/partner-ordering-ui/components/resuable/Spinner";

interface SectionLayoutProps {
  title: string;
  description: string;
  loading?: boolean;
}

const SectionLayout = ({
  title,
  description,
  loading,
  children,
}: PropsWithChildren<SectionLayoutProps>) => {
  const { mediaStyles } = useContext(ResponsiveContext);

  return (
    <View
      style={[
        COMMON_STYLES.pv2,
        BG_STYLES.bgBlue10,
        COMMON_STYLES.mv1,
        mediaStyles({
          desktopStyle: [styles["br5/4"], COMMON_STYLES.row, COMMON_STYLES.ph2],
          mobileStyle: COMMON_STYLES["ph3/2"],
        }),
      ]}
    >
      <View
        style={mediaStyles({
          desktopStyle: [COMMON_STYLES.mr1, { width: 227 }],
        })}
      >
        <Text
          style={[
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral100,
            COMMON_STYLES["mb1/4"],
            mediaStyles({
              desktopStyle: TYPOGRAPHY.HEADING1,
              mobileStyle: TYPOGRAPHY.HEADING3,
            }),
          ]}
        >
          {title}
        </Text>
        <Text
          style={[
            VARIANTS_STYLES.MEDIUM,
            TEXT_STYLES.textNeutral80,
            mediaStyles({
              desktopStyle: TYPOGRAPHY.TEXT1,
              mobileStyle: TYPOGRAPHY.TEXT2,
            }),
          ]}
        >
          {description}
        </Text>
      </View>
      {loading ? (
        <Spinner />
      ) : (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={COMMON_STYLES.flex1}
        >
          <View
            style={[
              COMMON_STYLES.flex1,
              mediaStyles({
                desktopStyle: [COMMON_STYLES.row, styles.gap1],
                mobileStyle: [
                  COMMON_STYLES.mt1,
                  styles["gap1/2"],
                  COMMON_STYLES.row,
                ],
              }),
            ]}
          >
            {children}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  gap1: { gap: 16 },
  "gap1/2": { gap: 8 },
  "br5/4": { borderRadius: 24 },
});

export default SectionLayout;
