import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  CrossIcon,
  Input,
} from "@dehaat/dds";
import LensIcon from "app/icons/Lens";
import React from "react";
import { Pressable, StyleProp, ViewStyle } from "react-native";

interface Props {
  value: string;
  setValue: (value: string) => void;
  onClear: () => void;
  placeholder?: string;
  containerStyle?: StyleProp<ViewStyle>;
}

const SearchInput = ({
  value,
  setValue,
  onClear,
  placeholder,
  containerStyle,
}: Props) => {
  return (
    <Input
      leftLabelIcon={
        <LensIcon
          svgProps={{
            width: 20,
            height: 20,
            fill: "none",
            style: COMMON_STYLES["mr0.5"],
          }}
        />
      }
      value={value}
      onChangeText={setValue}
      rightLabelIcon={
        value ? (
          <Pressable
            style={[
              COMMON_STYLES.justifyCenter,
              COMMON_STYLES.alignItemsCenter,
            ]}
            onPress={onClear}
          >
            <CrossIcon
              width={20}
              height={20}
              stroke={COLORS.neutral70}
              strokeWidth={1.5}
            />
          </Pressable>
        ) : null
      }
      containerStyle={[
        BG_STYLES.bgWhite,
        COMMON_STYLES.b1,
        BORDER_STYLES.borderNeutral20,
        containerStyle,
      ]}
      label={placeholder}
    />
  );
};

export default SearchInput;
