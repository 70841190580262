import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../partner-ordering-ui/store/reducer/cart";
import nativeSlice from "./reducer/native";
import appSlice from "../partner-ordering-ui/store/reducer/app";
import authTokenSlice from "../store/reducer/authToken";
import ledgerSlice from "app/ledger/reducer";

const store = configureStore({
  reducer: {
    cart: cartSlice,
    native: nativeSlice,
    app: appSlice,
    authToken: authTokenSlice,
    ledger: ledgerSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
