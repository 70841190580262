import React from "react";
import { Rect, Svg, SvgProps } from "react-native-svg";

const MenuIcon = ({ fill, ...props }: SvgProps) => {
  return (
    <Svg viewBox="0 0 24 25" fill="none" {...props}>
      <Rect
        x="3"
        y="5.03125"
        width="18"
        height="2.5"
        rx="1.25"
        fill={fill || "#101010"}
      />
      <Rect
        x="3"
        y="11.0664"
        width="18"
        height="2.5"
        rx="1.25"
        fill={fill || "#101010"}
      />
      <Rect
        x="3"
        y="17.1016"
        width="18"
        height="2.5"
        rx="1.25"
        fill={fill || "#101010"}
      />
    </Svg>
  );
};

export default MenuIcon;
