import React, { useContext, useEffect, useState } from "react";
import { View, Text, ScrollView } from "react-native";
import {
  BORDER_STYLES,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import RoundImageWithName from "app/components/reusable/RoundImageWithName";
import { Crop } from "app/farmer360/models/farmer";
import fetchFarmerCrops from "app/farmer360/apis/fetchFarmerCrops";
import Loader from "./Loader";
import EmptySectionCard from "../reusable/EmtySectionCard";
import { ResponsiveContext } from "app/context/ResponsiveUIContext";

interface Props {
  farmerId: string;
}

const Crops = ({ farmerId }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [crops, setCrops] = useState<Crop[]>([]);

  const { mediaStyles } = useContext(ResponsiveContext);

  const fetchAndSetFarmerCrops = async () => {
    const results = await fetchFarmerCrops(farmerId);
    if (results) {
      setCrops(results);
    }
  };

  useEffect(() => {
    fetchAndSetFarmerCrops().finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View
      style={[
        COMMON_STYLES.mv1,
        mediaStyles({ mobileStyle: COMMON_STYLES.ph1 }),
      ]}
    >
      <Text
        style={[
          TYPOGRAPHY.HEADING3,
          VARIANTS_STYLES.SEMI_EMPHASIZED,
          TEXT_STYLES.textNeutral100,
        ]}
      >
        Crops
      </Text>
      {isLoading ? (
        <Loader />
      ) : (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={COMMON_STYLES.flex1}
        >
          <View
            style={[COMMON_STYLES.row, COMMON_STYLES.mv1, COMMON_STYLES.flex1]}
          >
            <>
              {crops.map(({ id, image_url, name }) => (
                <RoundImageWithName
                  name={name}
                  imgUrl={image_url}
                  handleItemClick={() => {}}
                  key={id}
                  imageStyle={[BORDER_STYLES.borderNeutral10, COMMON_STYLES.b1]}
                />
              ))}
            </>
            {crops.length === 0 && (
              <EmptySectionCard title={"No record found."} />
            )}
          </View>
        </ScrollView>
      )}
    </View>
  );
};

export default Crops;
