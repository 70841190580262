import React from "react";
import { StyleSheet, Text, View } from "react-native";

import {
  BG_STYLES,
  BORDER_STYLES,
  COLORS,
  COMMON_STYLES,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";
import ActionCard from "../reusable/ActionCard";

export interface Props {
  highlightDigits?: string;
  imageURL: string | null;
  name: string;
  address: string;
  onClick: () => void;
  phoneNumber?: string;
  backgroundColor?: string;
}

const FarmerCard = ({
  highlightDigits = "",
  name,
  address,
  imageURL,
  backgroundColor,
  phoneNumber,
  onClick,
}: Props) => {
  return (
    <ActionCard
      heading={{
        text: name,
        colour: COLORS.neutral100,
      }}
      description={{
        text: address,
        colour: COLORS.neutral80,
      }}
      label={
        phoneNumber ? (
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.BASE,
              TEXT_STYLES.textNeutral80,
              COMMON_STYLES["mt1/8"],
            ]}
          >
            {phoneNumber}
          </Text>
        ) : null
      }
      showAll
      image={imageURL}
      imageFallback={
        <View
          style={[
            COMMON_STYLES.alignItemsCenter,
            COMMON_STYLES.justifyCenter,
            styles.image,
            { backgroundColor },
          ]}
        >
          <Text style={[TEXT_STYLES.textWhite, TYPOGRAPHY.HEADING1]}>
            {name[0]}
          </Text>
        </View>
      }
      viewStyle={[
        COMMON_STYLES.br1,
        BORDER_STYLES.borderNeutral20,
        BG_STYLES.bgNeutral00,
        COMMON_STYLES.ph1,
        COMMON_STYLES["pv1/2"],
      ]}
      onClick={onClick}
    />
  );
};

const styles = StyleSheet.create({
  image: {
    borderRadius: 99,
    width: 44,
    height: 44,
  },
});

export default FarmerCard;
