import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddItemToCart,
  CartBundleSKUInfo,
  CartClearanceSKUInfo,
  CartData,
  CartItem,
  CartSingleSKUInfo,
  ClearanceCartItem,
  ClearanceItem,
  ComboProduct,
  DeleteCartItem,
  SingleProduct,
  UpdateCartItem
} from "../../models/Cart";

import type { RootState } from "../../../store/index";

import fetchProductsCartInfo from "app/partner-ordering-ui/apis/fetchProductsCartInfo";
import { DeliverySlot } from "app/partner-ordering-ui/apis/getDeliverySlots";
import { SchemeInfo } from "app/partner-ordering-ui/models/SchemeInfo";
import { deepClone } from "app/partner-ordering-ui/utils/helpers/common";
import { removeData, storeData } from "../../../utils/storage";
import { PRODUCT_TYPE } from "../../constants/Product";
import { STORAGE_KEYS } from "../../constants/storage";
import { CumulativeCoupon } from "../../models/Product";
import {
  addItemToCartHandler,
  createCartQuery,
  deleteCartItemHandler,
  resetError,
  updateCartInfo,
  updateCartItemHandler,
} from "../../utils/helpers/cart";
import { CART_ERROR_KEYS } from "app/partner-ordering-ui/constants/Cart";

export interface CartInfo {
  cart_info: {
    order_total: number | null;
    instant_discount: number | null;
    payable: number | null;
    cashback: number | null;
  };
  products: (SingleProduct | ComboProduct | ClearanceItem)[];
  cumulative_coupons: CumulativeCoupon[];
  scheme_info: SchemeInfo | null;
}

// Define a type for the slice state
export interface CartState {
  cartId?: string;
  items: Record<string, CartItem>;
  clearanceItems: Record<string, ClearanceCartItem>;
  data: CartInfo;
  cartData: CartData;
  schemeId: string | null;
}

// Define the initial state using that type
const initialState: CartState = {
  items: {},
  clearanceItems: {},
  data: {
    cart_info: {
      order_total: null,
      instant_discount: null,
      payable: null,
      cashback: null,
    },
    products: [],
    cumulative_coupons: [],
    scheme_info: null,
  },
  cartData: {},
  schemeId: null,
};

export const addItemToCart = createAsyncThunk(
  "cart/addItemToCart",
  async ({ id, product, batchId }: AddItemToCart, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = addItemToCartHandler(id, product, cart, batchId);
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async ({ id, qty, batchId }: UpdateCartItem, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = updateCartItemHandler(id, cart, qty, batchId);
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

export const deleteCartItem = createAsyncThunk(
  "cart/deleteCartItem",
  async ({ id, batchId }: DeleteCartItem, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = deleteCartItemHandler(id, cart, batchId);
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

export const resetCart = createAsyncThunk(
  "cart/resetCart",
  async (_, { getState }) => {
    const { native } = getState() as RootState;
    removeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`);
  }
);

export const setCartInfo = createAsyncThunk(
  "cart/setCartInfo",
  async ({ cartInfo }: { cartInfo: CartInfo | null }, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = updateCartInfo(cartInfo || initialState.data, cart);
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

export const fetchAndSetCartInfo = createAsyncThunk(
  "cart/fetchAndSetCartInfo",
  async (_, { getState }) => {
    const { cart, native } = getState() as RootState;
    const query = createCartQuery(cart.items, cart.clearanceItems);
    const response = await fetchProductsCartInfo(
      query,
      native.dc.ib_id,
      cart.schemeId
    );
    const newCart = updateCartInfo(
      response ? response.data : initialState.data,
      cart
    );
    if (newCart.data.scheme_info) {
      newCart.data.scheme_info.scheme_id = cart.schemeId;
    }

    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

const updateCartDataAndItemsQuery = (data: CartData, cart: CartState) => {
  try {
    const newCart = deepClone(cart);
    newCart.cartData = data;
    Object.values(data).forEach((item) => {
      if (item.isBundle) {
        const {
          id,
          unitPrice,
          discountedPrice,
          quantity,
          paymentModes,
          deliveryFee
        } = item as CartBundleSKUInfo;
        newCart.items[id].query = {
          id: id,
          total_order_quantity: quantity,
          product_type: PRODUCT_TYPE.REGULAR,
          is_bundle: true,
          payment_modes: paymentModes,
          unit_price: unitPrice.toString(),
          discount_unit_price: discountedPrice?.toString(),
          delivery_fee: deliveryFee?.toString(),
        };
      } else {
        if (item.productType === PRODUCT_TYPE.CLEARANCE) {
          const {
            id,
            unitPrice,
            discountedPrice,
            quantity,
            paymentModes,
            expiryDate,
            batchId,
            deliveryFee
          } = item as CartClearanceSKUInfo;
          newCart.clearanceItems[batchId].query = {
            id,
            total_order_quantity: quantity,
            product_type: PRODUCT_TYPE.CLEARANCE,
            payment_modes: paymentModes,
            is_bundle: false,
            unit_price: unitPrice.toString(),
            discount_unit_price: discountedPrice!.toString(),
            product_expiry: expiryDate,
            batch_id: batchId,
            delivery_fee: deliveryFee?.toString(),
          };
        } else {
          const { id, unitPrice, quantity, paymentModes, deliveryFee, slots } =
            item as CartSingleSKUInfo;
          newCart.items[id].query = {
            id,
            total_order_quantity: quantity,
            product_type: PRODUCT_TYPE.REGULAR,
            payment_modes: paymentModes,
            is_bundle: false,
            unit_price: unitPrice.toString(),
            delivery_fee: deliveryFee?.toString(),
            slots: slots
          };
        }
      }
    });
    return newCart;
  } catch (err) {
    console.error("Error: ", err);
  }
  return cart;
};

export const updateValidateCartData = createAsyncThunk(
  "cart/updateValidateCartData",
  async ({ data }: { data: CartData }, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = updateCartDataAndItemsQuery(data, cart);
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

export const setCartId = createAsyncThunk(
  "cart/setCartId",
  async ({ id }: { id?: string }, { getState }) => {
    const { cart, native } = getState() as RootState;
    const newCart = deepClone(cart);
    newCart.cartId = id;
    storeData(`${STORAGE_KEYS.MY_CART}${native.dc.ib_id}`, newCart);
    return newCart;
  }
);

const updateDeliverySlot = (productId: string, batchId: string | undefined, deliverySlots: DeliverySlot[], cart: CartState) => {
  const newCart = deepClone(cart);
  const cartItem = batchId ? newCart.cartData[batchId] : newCart.cartData[productId] || {}
  cartItem.slots = deliverySlots
  resetError(CART_ERROR_KEYS.INVALID_DELIVERY_SLOT, cartItem)
  const item = batchId ? newCart.clearanceItems[batchId]?.query : newCart.items[productId]?.query || {}
  item.slots = deliverySlots
  return newCart;
};

export const cart = createSlice({
  name: "cart",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    updateCart: (state, action: PayloadAction<CartState | null>) => {
      const { items, clearanceItems, cartId, data, cartData } =
        action.payload || initialState;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    },
    updateNegotiablePrice: (
      state,
      action: PayloadAction<{ key: string | number; price: string }>
    ) => {
      const { key, price } = action.payload;
      (state.cartData[key] as CartSingleSKUInfo).negotiatedPrice = price;
    },
    updateSchemeId: (
      state,
      action: PayloadAction<{ schemeId: string | null }>
    ) => {
      const { schemeId } = action.payload;
      state.schemeId = schemeId;
    },
    updateDeliverySlotCartData: (state, action: PayloadAction<{ productId: string, batchId: string | undefined, slots: DeliverySlot[], ib_id: string }>) => {
      const { productId, slots, ib_id, batchId } = action.payload;
      const newCart = updateDeliverySlot(productId, batchId, slots, state);
      storeData(`${STORAGE_KEYS.MY_CART}${ib_id}`, newCart);
      const { items, clearanceItems, cartId, data, cartData } = newCart;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addItemToCart.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(updateCartItem.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(deleteCartItem.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(resetCart.fulfilled, (state) => {
      const { items, clearanceItems, cartId, data, cartData } = initialState;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(setCartInfo.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(fetchAndSetCartInfo.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(updateValidateCartData.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
    builder.addCase(setCartId.fulfilled, (state, action) => {
      const { items, clearanceItems, cartId, data, cartData } = action.payload;
      state.items = items;
      state.clearanceItems = clearanceItems;
      state.cartId = cartId;
      state.data = data;
      state.cartData = cartData;
    });
  },
});

export const { updateCart, updateNegotiablePrice, updateSchemeId, updateDeliverySlotCartData } =
  cart.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectItems = (state: RootState) => state.cart.items;
export const selectClearanceItems = (state: RootState) =>
  state.cart.clearanceItems;
export const selectCartInfo = (state: RootState) => state.cart.data;
export const selectCartId = (state: RootState) => state.cart.cartId;

export const selectCumulativeCouponInfo =
  (code: string) => (state: RootState) => {
    return state.cart.data.cumulative_coupons.find(
      (coupon) => coupon.coupon_code === code
    );
  };

export const selectCartData = (state: RootState) => state.cart.cartData;
export const selectSchemeId = (state: RootState) => state.cart.schemeId;

export default cart.reducer;
