import React, { useContext, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { BG_STYLES, COMMON_STYLES } from "@dehaat/dds";

import { ResponsiveContext } from "app/context/ResponsiveUIContext";

import FarmerProfileSection from "../components/profile";
// import KYCStatusCard from "../components/kyc";
// import SavedAddressesSection from "../components/address";
// import CouponsSection from "../components/coupons";
import FarmReportsSection from "../components/farms";
import OrdersSection from "../components/orders";
// import ServiceListSection from "../components/services";
import Crops from "../components/crops";
// import CropCalendar from "../components/crop-calendar";
// import HarvestedCrops from "../components/crops/HarvestedCropsSection";
// import TicketsSection from "../components/tickets";
// import InsuranceSection from "../components/insurance";
// import FarmerFinanceSection from "../components/finance";
import { Farmer360HomeScreenProps } from "../routes/types";

const Farmer360Details = ({ route, navigation }: Farmer360HomeScreenProps) => {
  const { mediaStyles } = useContext(ResponsiveContext);

  const { farmerId, phoneNumber, name } = route.params;

  useEffect(() => {
    navigation.setOptions({
      title: name || phoneNumber,
    });
  }, [name, navigation, phoneNumber]);

  return (
    <View
      style={[
        COMMON_STYLES.row,
        COMMON_STYLES.justifyCenter,
        BG_STYLES.bgWhite,
      ]}
    >
      <View
        style={[COMMON_STYLES.flex1, mediaStyles({ desktopStyle: styles.mw })]}
      >
        <FarmerProfileSection farmerId={farmerId} />
        <Crops farmerId={farmerId} />
        {/* <KYCStatusCard /> */}
        {/* <SavedAddressesSection /> */}
        {/* <CouponsSection /> */}
        <OrdersSection farmerId={farmerId} />
        {/* <ServiceListSection /> */}
        <FarmReportsSection farmerId={farmerId} />
        {/* <CropCalendar />
        <HarvestedCrops />
        <TicketsSection />
        <InsuranceSection />
        <FarmerFinanceSection /> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mw: {
    maxWidth: 1260,
  },
});

export default Farmer360Details;
