import React, { useCallback, useEffect, useState } from "react";

import { formatCurrency } from "@dehaat/dds";

import SectionLayout from "app/farmer360/components/reusable/SectionLayout";
import fetchFarmerOrders from "app/farmer360/apis/fetchFarmerOrders";
import { Order, OrderAggregate } from "app/farmer360/models/orders";

import OrderListingCard from "./OrderListingCard";
import EmptySectionCard from "../reusable/EmtySectionCard";

interface Props {
  farmerId: string;
}

const OrdersSection = ({ farmerId }: Props) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [aggregate, setAggregate] = useState<OrderAggregate>();
  const [loading, setLoading] = useState(true);

  const fetchFarmerOrdersAndSet = useCallback(async () => {
    const response = await fetchFarmerOrders(farmerId);
    if (response) {
      setOrders(response.results);
      setAggregate(response.orders_aggregate);
    }
  }, [farmerId]);

  const getDescription = () => {
    if (loading || !aggregate) {
      return "";
    }
    const { total_count, duration, total_amount } = aggregate;
    return `Number of orders: ${total_count}, \nTotal purchase in last ${duration}: ${formatCurrency(
      total_amount
    )}/-`;
  };

  useEffect(() => {
    fetchFarmerOrdersAndSet().finally(() => setLoading(false));
  }, [fetchFarmerOrdersAndSet]);

  return (
    <SectionLayout
      title="Orders"
      description={getDescription()}
      loading={loading}
    >
      {orders.map((order) => (
        <OrderListingCard
          orderId={order.id}
          date={order.created_at}
          price={order.grand_total}
          name={order.buyer_full_name}
          orderLines={order.order_lines}
        />
      ))}
      {orders.length === 0 && <EmptySectionCard title="No order history" />}
    </SectionLayout>
  );
};

export default OrdersSection;
