import { get } from "app/sell-to-farmer/utils/axios";
import { Farm } from "../models/farmer";
import { API } from "../models/api";

const fetchFarmerFarmList = async (farmerId: string) => {
  try {
    const url = `/farmer-360/v1/farmer/${farmerId}/farms`;
    const response = await get<API<Farm>>(url);
    return response.results;
  } catch (e) {
    return [];
  }
};

export default fetchFarmerFarmList;
