import React from "react";
import { View, Text, Linking } from "react-native";
import {
  BG_STYLES,
  BORDER_STYLES,
  Button,
  BUTTON_STATUS,
  COLORS,
  COMMON_STYLES,
  LazyImage,
  SIZE,
  TEXT_STYLES,
  TYPOGRAPHY,
  VARIANTS_STYLES,
} from "@dehaat/dds";

import FileIcon from "app/icons/File";
// import FarmReportDrawer from "./FarmReport";

interface Props {
  farmImage: string;
  farmName: string;
  land: string;
  latLng: string;
  reportUrl: string | null;
}

const FarmReportCard = ({
  farmImage,
  farmName,
  land,
  latLng,
  reportUrl,
}: Props) => {
  // const [isReportDrawerVisible, setReportDrawerVisibility] = useState(false);

  return (
    <View style={{}}>
      <View
        style={[
          COMMON_STYLES.b1,
          BORDER_STYLES.borderBlue20,
          BG_STYLES.bgWhite,
          COMMON_STYLES.br1,
          COMMON_STYLES.ofHidden,
          { width: 292 },
        ]}
      >
        <LazyImage
          imageUrl={farmImage}
          imgStyles={{ width: "100%", height: 171, margin: 8 }}
          fallBackIcon={
            <View
              style={{
                width: "100%",
                height: 171,
                margin: 8,
                backgroundColor: COLORS.neutral20,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>No Image</Text>
            </View>
          }
        />
        <View style={[COMMON_STYLES.ph1, COMMON_STYLES.pb1]}>
          <Text
            style={[
              TYPOGRAPHY.TEXT1,
              VARIANTS_STYLES.SEMI_EMPHASIZED,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {farmName}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            {land || "Land Area: --"}
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            Survey No: --
          </Text>
          <Text
            style={[
              TYPOGRAPHY.TEXT2,
              VARIANTS_STYLES.MEDIUM,
              TEXT_STYLES.textNeutral80,
            ]}
          >
            Coordinates: {latLng}
          </Text>
        </View>
      </View>
      <Button
        onClick={() =>
          reportUrl &&
          // setReportDrawerVisibility(true)
          Linking.openURL(reportUrl)
        }
        text={"View Reports"}
        buttonStyle={COMMON_STYLES["mt1/2"]}
        textStyle={[TYPOGRAPHY.TEXT2, VARIANTS_STYLES.MEDIUM]}
        size={SIZE.large}
        iconLeft={<FileIcon />}
        status={reportUrl ? BUTTON_STATUS.ACTIVE : BUTTON_STATUS.DISABLED}
      />

      {/* <FarmReportDrawer
        reportUrl={reportUrl!}
        isVisible={isReportDrawerVisible}
        onClose={() => setReportDrawerVisibility(false)}
      /> */}
    </View>
  );
};

export default FarmReportCard;
